import React from 'react';

import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../../constants';

import { useSurfacingExperimentContext } from '../../../../contexts';

import { OrganizerTrustSignalInfo } from '../OrganizerTrustSignalInfo/OrganizerTrustSignalInfo';
import { OrganizerTrustSignalInfoVariant } from '../OrganizerTrustSignalInfoVariant/OrganizerTrustSignalInfoVariant';

import styles from './OrganiserListingPage.module.scss';

import type { FeatureFlags } from '../../../../types';

export interface OrganiserListingPageProps {
    organizerName: string;
    organizerId: string;
    organizerDescription?: string;
    trustSignal?: React.ReactNode;
    signalType?: string;
    organizerAvatar?: string;
    pageArea?: string;
    isMobile?: boolean;
    showBadge?: boolean;
    featureFlags?: FeatureFlags;
}

const OrganiserListingPage = ({
    organizerName,
    organizerAvatar,
    organizerId,
    organizerDescription,
    pageArea = LISTING_HEAP_PAGE_AREA_IDS.ORGANIZER_PANEL,
    showBadge,
    trustSignal,
    signalType,
    featureFlags,
    isMobile,
}: OrganiserListingPageProps) => {
    const { variant, showOrganiserInfoLoader } =
        useSurfacingExperimentContext();

    if (showOrganiserInfoLoader) return <div className={styles.shimmerRoot} />;

    const SignalInfo =
        variant === 'control'
            ? OrganizerTrustSignalInfo
            : OrganizerTrustSignalInfoVariant;

    return (
        <div data-testid="creator-signal-main-section">
            <SignalInfo
                organizerName={organizerName}
                organizerAvatar={organizerAvatar}
                organizerId={organizerId}
                organizerDescription={organizerDescription}
                pageArea={pageArea}
                trustSignal={trustSignal}
                signalType={signalType}
                featureFlags={featureFlags}
                showBadge={showBadge}
                isMobile={isMobile}
            />
        </div>
    );
};

export default OrganiserListingPage;
