import React, { useState } from 'react';
import { LISTING_HEAP_IDS } from '../../../../constants';
import type { NativeVideoProps } from '../../../../types';
import { Modal } from '../../../_shared/Modal';

import { Play } from '../../../_shared/Icons';

import './NativeVideo.scss';

type NativeVideoComponentProps = NativeVideoProps & {
    alt?: string;
};

export const NativeVideo: React.FC<NativeVideoComponentProps> = ({
    alt,
    url,
    thumbnail,
}) => {
    const [shouldShowVideo, setShouldShowVideo] = useState(false);

    return (
        <>
            <div className="video-wrapper">
                <div className="video-overlay NativeVideo__play-button-wrapper">
                    <button
                        data-heap-id={LISTING_HEAP_IDS.HERO_VIDEO}
                        className="video-play-button"
                        aria-label="Play"
                        onClick={() => setShouldShowVideo(true)}
                    >
                        <div className="video-play-button__icon">
                            <Play />
                        </div>
                    </button>
                </div>
                <img
                    className="NativeVideo__thumbnail"
                    alt={`${alt} thumbnail`}
                    src={thumbnail}
                    data-testid="hero-img"
                />
            </div>
            {shouldShowVideo && (
                <Modal
                    visible={true}
                    type="simple"
                    noMinHeight={true}
                    noPadding={true}
                    onClose={() => setShouldShowVideo(false)}
                    __containerClassName="NativeVideo__modal"
                >
                    <div
                        className="NativeVideo__wrapper"
                        onClick={() => setShouldShowVideo(false)}
                        data-testid="native-video-content"
                    >
                        <video
                            className="NativeVideo__video"
                            title={alt || 'Native video content'}
                            src={url}
                            controls
                            autoPlay
                            loop
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
