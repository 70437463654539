import React from 'react';

import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../../../../constants';

import { FollowButton } from '../../../../FollowButton';

import styles from './OrganizerInfoModalFooter.module.scss';

export const OrganizerInfoModalFooter = () => {
    return (
        <div className={styles.root}>
            <FollowButton
                pageArea={LISTING_HEAP_PAGE_AREA_IDS.TOP_SECTION_MODAL}
            />
        </div>
    );
};
