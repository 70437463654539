import {
    EventCardSaveAction,
    EventCardShareAction,
    FormattedEvent,
    getShareActionProps,
} from '@eventbrite/event-renderer';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { STATSIG_EVENT_NAMES } from '../../experimentation';
import {
    label,
    ShareType,
    trackShareAction,
} from '../../tracking/trackShareAction';
import { GACodes } from '../../types';
import { ListingsEventCard } from '../_shared/EventCard/ListingsEventCard';
import type { EventCardsMapProps } from './types';
import { AFF_BUDGET_AND_NEARBY_EVENTS_LIVE } from './types';

export function getEventCardsMap({
    events,
    isAuthenticated,
    locale,
    bucketLabel,
}: EventCardsMapProps): JSX.Element[] {
    return events.map((event: FormattedEvent) => {
        const affCode = AFF_BUDGET_AND_NEARBY_EVENTS_LIVE;
        const eventKey = event.promotedListingMetadata?.adId || event.id;
        const shareActionProps = getShareActionProps({
            name: event.name,
            id: event.id,
            url: event.url,
            affCode: affCode,
            onShareItemClick: (id: string) =>
                trackShareAction(
                    label(id as ShareType),
                    GACodes.GA_CATEGORY_EVENT_CARD,
                ),
            onClick: () => {
                trackShareAction(
                    GACodes.GA_LABEL_OPEN_MODAL,
                    GACodes.GA_CATEGORY_EVENT_CARD,
                );
                logEvent(
                    STATSIG_EVENT_NAMES.LISTING_BUDGET_AND_NEARBY_EVENT_SHARE,
                );
            },
        });

        return (
            <ListingsEventCard
                key={eventKey}
                locale={locale || 'en-US'}
                event={event}
                affCode={affCode}
                onClick={() => {
                    logEvent(
                        STATSIG_EVENT_NAMES.LISTING_BUDGET_AND_NEARBY_EVENT_CLICK,
                        null,
                    );
                }}
                bucketLabel={bucketLabel}
                actions={
                    <>
                        <EventCardSaveAction
                            isAuthenticated={!!isAuthenticated}
                            savedByYou={event.savedByYou}
                            id={event.id}
                            eventName={event.name}
                            imageUrl={event.imageUrl}
                            actionStyle="outline"
                            onClick={(saved) =>
                                saved &&
                                logEvent(
                                    STATSIG_EVENT_NAMES.LISTING_BUDGET_AND_NEARBY_EVENT_LIKE,
                                )
                            }
                        />
                        <EventCardShareAction
                            actionStyle="outline"
                            {...shareActionProps}
                        />
                    </>
                }
            />
        );
    });
}
