import React, { type FC } from 'react';

import { gettext } from '@eventbrite/i18n';
import { numberFormatter } from '../../../../../utils/utils';

import { FOLLOWERS_THRESHOLD } from '../../../../constants';

import { useFollowCount } from '../../../../FollowButton/FollowButton';

import { OrganizerAvatar } from '../../../DescriptiveOrganizerPanelViewVariant/OrganizerAvatar';

import styles from './OrganizerHighlight.module.scss';

import type { IOrganizerInfoModalProps } from '../OrganizerInfoModal';

export type TOrganizerHighlightProps = Pick<
    IOrganizerInfoModalProps,
    'organizerName' | 'profileImageUrl' | 'trustSignal'
>;

export const OrganizerHighlight: FC<TOrganizerHighlightProps> = ({
    organizerName,
    profileImageUrl,
    trustSignal,
}) => {
    const followersCount = useFollowCount() ?? 0;

    return (
        <div className={styles.root}>
            <OrganizerAvatar organizerAvatar={profileImageUrl} />
            <div className={styles.organizerInfoRoot}>
                {organizerName ? (
                    <h3
                        className={`${styles.organizerName} eds-text-color--grey-800`}
                    >
                        {organizerName}
                    </h3>
                ) : null}
                <ul className={styles.companyInfoList}>
                    <li>{trustSignal}</li>
                    {followersCount > FOLLOWERS_THRESHOLD ? (
                        <li>
                            <strong>{numberFormatter(followersCount)}</strong>{' '}
                            {gettext('following this organiser')}
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};
