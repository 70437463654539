import React from 'react';

import { gettext } from '@eventbrite/i18n';
import { getSummaryList } from './ReviewSummary.helper';

import { useSurfacingExperimentContext } from '../../../../../../contexts';

import { SummaryRow } from './SummaryRow';

import styles from './ReviewSummary.module.scss';

export const ReviewSummary = () => {
    const { ratingsData } = useSurfacingExperimentContext();
    if (!ratingsData) return null;

    const [firstRow, secondRow] = getSummaryList(ratingsData);

    return (
        <div>
            <p className={styles.sectionHeading}>
                {gettext('They’re known for:')}
            </p>
            <div className={styles.summaryContainer}>
                <SummaryRow row={firstRow} />
                <SummaryRow row={secondRow} />
            </div>
        </div>
    );
};
