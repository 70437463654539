import {
    SegmentedCarousel,
    useSegmentedCarouselController,
} from '@eventbrite/collection-carousel';
import {
    DEFAULT_DESTINATION_EXPANSIONS,
    FormattedEvent,
} from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { Typography } from '@eventbrite/marmalade';
import {
    OrganicEventSearchFiltersApiPayload,
    searchOrganicEvents,
    transformResponse,
} from '@eventbrite/search-utils';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import type { LocationData } from '../../types';
import { calculateTopOffset } from '../RelatedEvents/OtherEventsYouMayLike/OtherEventsYouMayLikeSegmentedCarousel';
import { getEventCardsMap } from './getEventCardsMap';
import styles from './NightlifeEventsCarousel.module.scss';

interface NightlifeEventsProps {
    isAuthenticated: boolean;
    locationData: LocationData;
    locale: string;
}

const isMobile = calculateTopOffset();

const carouselHeading = (currentPlace: string) =>
    gettext('Live your best nightlife in %(currentPlace)s', {
        currentPlace: currentPlace,
    });

const Header = (currentPlace: string) => (
    <div className={styles.segmentedCarouselHeader}>
        <div className={styles.segmentedCarouselTitle}>
            <Typography color="neutral-900" variant="heading-sm">
                {carouselHeading(currentPlace)}
            </Typography>
        </div>
    </div>
);

const renderLoadingSkeleton = () => (
    <div className={styles.loadingSkeleton} data-testid="loading-skeleton">
        <LoadingSkeleton />
    </div>
);

const queryOverload = (
    placeId: string,
): OrganicEventSearchFiltersApiPayload => {
    return {
        page_size: 10,
        aggs: {},
        dates: ['current_future'],
        places: placeId ? [placeId] : [],
        available: true,
        tags: [
            'EventbriteCategory/103',
            'EventbriteCategory/104',
            'EventbriteCategory/110',
            'EventbriteCategory/116',
            'EventbriteSubCategory/4007',
            'EventbriteFormat/11',
        ],
        start_hour_range: {
            start_hour: 20,
            end_hour: 24,
        },
    };
};

export const NightlifeEventsCarousel: React.FC<NightlifeEventsProps> = ({
    isAuthenticated,
    locationData,
    locale,
}) => {
    const segmentedCarouselControllerProps = useSegmentedCarouselController();

    const nightlifeData = useQuery(
        ['listings', locationData],
        () =>
            searchOrganicEvents({
                event_search: queryOverload(locationData.placeId || ''),
                browse_surface: 'listings',
                'expand.destination_event': DEFAULT_DESTINATION_EXPANSIONS,
            }),
        {
            select: transformResponse,
            refetchOnMount: false,
            enabled: true,
        },
    );

    if (nightlifeData.isLoading) {
        return renderLoadingSkeleton();
    }

    const eventData = nightlifeData.data;
    if (!eventData || eventData?.events.length < 3) return null;

    const events: FormattedEvent[] = eventData?.events;

    const nightlifeEvents = getEventCardsMap({
        events,
        isAuthenticated,
        locale,
        bucketLabel: carouselHeading(
            locationData.currentPlace || '',
        ).toString(),
    });

    return (
        <div
            className={styles.nightlifeEventsWrapper}
            data-testid="nightlife-carousel"
        >
            <div className={styles.segmentedCarousel}>
                <SegmentedCarousel
                    {...{
                        ...segmentedCarouselControllerProps,
                        hideOverflow: !isMobile,
                        rootPaddingTop: 0,
                        rootPaddingBottom: 0,
                        rootPaddingLeft: isMobile ? 20 : 0,
                        rootPaddingRight: isMobile ? 20 : 0,
                        headerGap: 0,
                    }}
                >
                    <SegmentedCarousel.Header>
                        {Header(locationData.currentPlace || '')}
                    </SegmentedCarousel.Header>
                    <SegmentedCarousel.Track
                        key={`nightlife-events-${nightlifeEvents.length}`}
                    >
                        {nightlifeEvents}
                    </SegmentedCarousel.Track>

                    <SegmentedCarousel.Footer />
                </SegmentedCarousel>
            </div>
        </div>
    );
};
