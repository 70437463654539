import { useEffect } from 'react';
import { AppProps } from '../../types';
import { isProd } from '../utils';
import { initDatadogRUM } from './';

export const useDataDogRum = (props: AppProps): void => {
    useEffect(() => {
        if (
            props.settings.featureFlags?.enableDatadogRumLoadingFromListingsApp
        ) {
            initDatadogRUM({
                app: {
                    name: props.app_name,
                    version: props.app_version,
                },
                user: props.user,
                env: isProd(props.env.ebDomain) ? 'prod' : 'qa',
                sampleValue: props.settings.featureFlags.listingsRumSampleValue,
                featureFlags: {
                    listings_is_expired: props.event.isExpiredEvent,
                    listings_is_live: props.event.isLive,
                    listings_has_date_selector: !!props.event.childEvents,
                },
            });
        }
    }, [
        props.app_name,
        props.app_version,
        props.env,
        props.event,
        props.settings,
        props.user,
    ]);
};
