import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useIsFirstHierarchyLevel } from '../../../experimentation';

import './StartDateView.scss';

type StartDateViewProps = {
    dateTimeText: string;
    dateText: string;
};

export const StartDateView = ({
    dateTimeText,
    dateText,
}: StartDateViewProps) => {
    const isFirstHierarchyLevel = useIsFirstHierarchyLevel();
    return (
        <>
            {isFirstHierarchyLevel ? (
                <Typography color="neutral-1000" variant="body-lg">
                    <time className="start-date" dateTime={dateTimeText}>
                        {dateText}
                    </time>
                </Typography>
            ) : (
                <time className="start-date" dateTime={dateTimeText}>
                    {dateText}
                </time>
            )}
        </>
    );
};

export const MultipleDatesView = () => (
    <span className="start-date">{gettext('Multiple dates')}</span>
);
