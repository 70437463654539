import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../constants';
import { useEventBasicInformationContext } from '../../contexts';
import { GACodes } from '../../types';

export const CreatorBanner = () => {
    const { id, isRepeating, isSeriesParent, parentEventId } =
        useEventBasicInformationContext();
    const eventId =
        !isRepeating || (isRepeating && isSeriesParent) ? id : parentEventId;
    return (
        <div
            className="eds-notification-bar eds-notification-bar eds-notification-bar--neutral"
            role="alert"
        >
            <div className="eds-notification-bar__content">
                <div className="eds-notification-bar__content-child eds-g-cell">
                    {gettext('Need to make some updates?')}{' '}
                    <a
                        href={`/manage/events/${eventId}/details`}
                        target="_blank"
                        id="edit-event"
                        rel="noopener noreferrer"
                        onClick={() =>
                            trackAnalyticsEvent({
                                category: GACodes.GA_CATEGORY_LISTING,
                                action: GACodes.GA_ACTION_EDIT_EVENT,
                            })
                        }
                        data-heap-id={LISTING_HEAP_IDS.CREATOR_TOP_BANNER_EDIT}
                    >
                        {gettext('Edit event')}
                    </a>
                </div>
            </div>
        </div>
    );
};
