import React from 'react';

import { gettext } from '@eventbrite/i18n';
import { numberFormatter } from '../../../../../utils/utils';
import {
    getOrganizerRatingsDistributionList,
    getOrganizerRatingSvg,
} from './OrganizerRating.helper';

import { useSurfacingExperimentContext } from '../../../../../../contexts';

import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';

import styles from './OrganizerRating.module.scss';

export const OrganizerRating = () => {
    const { ratingsData } = useSurfacingExperimentContext();
    if (!ratingsData) return null;

    const rating = Number(ratingsData.overall_experience.average);
    const ratingsSvg = getOrganizerRatingSvg(rating);
    // prettier-ignore
    const ratingDistributions = getOrganizerRatingsDistributionList(ratingsData.overall_experience);

    return (
        <div className={styles.root}>
            <p className={styles.sectionHeading}>
                {gettext('Organiser rating:')}
            </p>
            <div className={styles.ratingsContainer}>
                <div>
                    <h4 className={styles.ratings}>{rating.toFixed(1)}</h4>
                    {ratingsSvg}
                    <p className={styles.totalRatings}>
                        {numberFormatter(
                            Number(ratingsData.overall_experience.total),
                        )}{' '}
                        {gettext('ratings')}
                    </p>
                </div>
                <ul className={styles.ratingDistributionList}>
                    {ratingDistributions.map((distribution) => (
                        <li
                            className={styles.ratingDistributionListItem}
                            key={distribution.number}
                        >
                            <span>{distribution.number}</span>
                            <ProgressIndicator
                                size="large-thin"
                                shape="linear"
                                type="determinate"
                                style="gradient"
                                progress={distribution.value}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
