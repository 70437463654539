import { flow, filter, map } from 'lodash/fp';
import { keys, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { SVG_PROP_TYPE } from '@eventbrite/eds-vector-image';
import { SIZES_JSON } from '@eventbrite/eds-iconography';

export const GRAPHIC_SIZES = flow(
    keys,
    filter((size) => size.indexOf('graphic-') >= 0),
    map((size) => size.replace('graphic-', '').replace('halo-', '')),
    uniq,
)(SIZES_JSON);

// PropTypes
export const GRAPHIC_TYPE_PROP_TYPE = SVG_PROP_TYPE;
export const GRAPHIC_SIZE_PROP_TYPE = PropTypes.oneOf(GRAPHIC_SIZES);
