import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';
import {
    useEventBasicInformationContext,
    useTicketContext,
} from '../../../contexts';
import { getCheckoutType } from '../../../experimentation';
import {
    CheckoutType,
    DiscountType,
    Event,
    FeatureFlags,
    LegacyConversionBarProps,
    SALES_STATUS,
} from '../../../types';
import { qualifiesForMultiticketSelection } from '../../Tickets/multiTicketsSelection';
import {
    ConversionBar,
    ConversionBarComponentProps,
} from './ConversionBar/ConversionBar';
import './ConversionBarContainer.scss';
import { DiscountSignal } from './DiscountSignal';
import { isTicketSelectionExcludedInListings } from './isTicketSelectionExcludedInListings';
import { TicketSelectionIframe } from './TicketSelectionIframe/TicketSelectionIframe';

export interface ConversionBarContainerProps
    extends ConversionBarComponentProps,
        LegacyConversionBarProps {
    compactCheckoutDisqualifications: Event['compactCheckoutDisqualifications'];
    ticketsInfo: Event['ticketsInfo'];
    salesStatus: Event['salesStatus']['sales_status'];
    discount?: Event['discount'];
    enableOpenDiscountsOnListings: FeatureFlags['enableOpenDiscountsOnListings'];
    isSalesEnded: boolean;
    isSoldOut: boolean;
    childEvents: Event['childEvents'];
    eventId: Event['id'];
    enableReldExpressCheckoutChanges: FeatureFlags['enableReldExpressCheckoutChanges'];
}

export const ConversionBarContainer: React.FC<ConversionBarContainerProps> = (
    props,
) => {
    const {
        salesStatus,
        ticketsInfo: { hasEarlyBirdTickets, hasBogoTickets } = {},
    } = useEventBasicInformationContext();
    const { ticketData } = useTicketContext();

    const compactCheckoutDisqualifications =
        props?.enableReldExpressCheckoutChanges
            ? ticketData.compactCheckoutDisqualifications
            : props.compactCheckoutDisqualifications;

    const isTicketSelectionExcluded = isTicketSelectionExcludedInListings({
        soldOut: compactCheckoutDisqualifications?.sold_out,
        reservedSeating: compactCheckoutDisqualifications?.reserved_seating,
        moreThanOneTicket:
            compactCheckoutDisqualifications?.more_than_one_ticket,
        isSalesEnded: compactCheckoutDisqualifications?.is_sales_ended,
        isRsvp: compactCheckoutDisqualifications?.is_rsvp,
        isPostponed: compactCheckoutDisqualifications?.is_postponed,
        isCanceled: compactCheckoutDisqualifications?.is_canceled,
        hasWaitlist: compactCheckoutDisqualifications?.has_waitlist,
        hasWaitingRoom: compactCheckoutDisqualifications?.has_waiting_room,
        hasDiscounts: compactCheckoutDisqualifications?.has_discounts,
        hasAddons: compactCheckoutDisqualifications?.has_addons,
        discountCode: compactCheckoutDisqualifications?.discount_code,
        hasDonations: compactCheckoutDisqualifications?.has_donations,
        hasInventoryTiers:
            compactCheckoutDisqualifications?.has_inventory_tiers,
        externalTicketsAreAvailable: props.externalTicketsAreAvailable,
        salesStatus,
    });

    const springRef = useSpringRef();
    const [containerSprings] = useSpring(
        () => ({
            ref: springRef,
            from: { transform: 'translateY(180px)' },
            to: { transform: 'translateY(0)' },
            config: {
                damping: 15,
                tension: 100,
                precision: 0.0001,
            },
        }),
        [],
    );
    const transRef = useSpringRef();
    const [signalSprings] = useSpring(
        () => ({
            ref: transRef,
            from: { transform: 'translateY(32px)' },
            to: { transform: 'translateY(0)' },
            config: {
                damping: 24,
                tension: 256,
            },
        }),
        [],
    );

    const {
        discount,
        enableOpenDiscountsOnListings,
        enableReldExpressCheckoutChanges,
    } = props;
    const discountApplied = discount?.percentOff || discount?.amountOff;
    const shouldShowOpenDiscount =
        enableOpenDiscountsOnListings &&
        discount?.discountType === DiscountType.open &&
        discountApplied;

    useChain([springRef, transRef], [0, 1], 1500);

    return (
        <animated.div
            style={containerSprings}
            className="conversion-bar-container"
        >
            <animated.div
                style={signalSprings}
                className="conversion-bar-signal"
            >
                {hasEarlyBirdTickets && (
                    <DiscountSignal>
                        {gettext('Early bird discount').toString()}
                    </DiscountSignal>
                )}
                {shouldShowOpenDiscount && (
                    <DiscountSignal>
                        {gettext('%(discountApplied)s off applied', {
                            discountApplied,
                        })}
                    </DiscountSignal>
                )}
                {hasBogoTickets && (
                    <DiscountSignal>{gettext('2 for 1 deal')}</DiscountSignal>
                )}
            </animated.div>
            <div className="conversion-bar-bordered">
                {props.children}
                <ConversionBarContent
                    {...props}
                    isTicketSelectionExcluded={isTicketSelectionExcluded}
                    enableReldExpressCheckoutChanges={
                        enableReldExpressCheckoutChanges
                    }
                    salesStatus={salesStatus}
                />
            </div>
        </animated.div>
    );
};

type ConversionBarContentProps = ConversionBarContainerProps & {
    isTicketSelectionExcluded: boolean;
    enableReldExpressCheckoutChanges?: boolean;
};

const ConversionBarContent: React.FC<ConversionBarContentProps> = ({
    isTicketSelectionExcluded,
    enableReldExpressCheckoutChanges,
    ...props
}) => {
    const { ticketData, totalTicketsSelected, isFetchComplete } =
        useTicketContext();
    const {
        salesStatus,
        childEvents,
        ticketsInfo: { hasBogoTickets } = {},
    } = useEventBasicInformationContext();
    const { compactCheckoutDisqualifications } = ticketData;
    const qualifies = qualifiesForMultiticketSelection(
        {
            compactCheckoutDisqualifications,
            childEvents,
            salesStatus,
        },
        ticketData.ticketClasses,
        hasBogoTickets,
    );
    const hasTicketsData = ticketData.ticketClasses.length > 0;
    const shouldShowTicketSelection =
        hasTicketsData && (!isTicketSelectionExcluded || qualifies);

    if (!isFetchComplete && enableReldExpressCheckoutChanges) {
        return (
            <div className="express-checkout-progressbar">
                <ProgressIndicator style="gradient" />
            </div>
        );
    }

    if (isFetchComplete && shouldShowTicketSelection) {
        return (
            <TicketSelection
                eventName={props.eventName}
                affiliateCode={props.affiliateCode}
                isPreview={props.isPreview}
                promoCode={props.promoCode}
                waitlistToken={props.waitlistToken}
                rsvpToken={props.rsvpToken}
                campaignToken={props.campaign_token}
                isMultiTicketEnabled={qualifies}
                areTicketSelected={totalTicketsSelected > 0}
            />
        );
    }

    if (isFetchComplete) {
        const panelPrice = enableReldExpressCheckoutChanges
            ? ticketData.panelDisplayPrice
            : props.panelDisplayPrice;
        return (
            <ConversionBar
                {...{
                    ...props,
                    panelDisplayPrice: panelPrice,
                    statusIsUnavailable:
                        salesStatus === SALES_STATUS.UNAVAILABLE,
                    salesStatus: salesStatus,
                }}
            />
        );
    }

    return null;
};

type TicketSelectionProps = Pick<
    ConversionBarContainerProps,
    | 'eventName'
    | 'affiliateCode'
    | 'isPreview'
    | 'promoCode'
    | 'waitlistToken'
    | 'rsvpToken'
> & {
    campaignToken: ConversionBarContainerProps['campaign_token'];
    isMultiTicketEnabled: boolean;
    areTicketSelected: boolean;
};

const TicketSelection = ({
    isMultiTicketEnabled,
    areTicketSelected,
    ...props
}: TicketSelectionProps) => {
    const { ticketData } = useTicketContext();
    const { compactCheckoutDisqualifications } = ticketData;
    const checkoutType = getCheckoutType(
        isMultiTicketEnabled,
        compactCheckoutDisqualifications,
    );

    return (
        <TicketSelectionIframe
            eventName={props.eventName as string}
            affiliateCode={props.affiliateCode}
            isPreview={props.isPreview}
            promoCode={props.promoCode}
            waitlistToken={props.waitlistToken}
            rsvpToken={props.rsvpToken}
            campaign_token={props.campaignToken}
            checkoutType={checkoutType}
            shouldShowGoToTickets={
                checkoutType === CheckoutType.OnlyCheckout && !areTicketSelected
            }
        />
    );
};
