import loadable from '@loadable/component';
import React, { FunctionComponent } from 'react';
const LazyReportThisEvent = loadable.lib(
    () => import('@eventbrite/report-this-event'),
);

interface ReportThisEventProps {
    domNodeId: string;
    onClose: () => void;
    isVisible: boolean;
    eventId: string;
    country: string | undefined;
}

export const ReportThisEventController: React.FunctionComponent<{
    isReportThisEventVisible: boolean;
    setIsReportThisEventVisible: Function;
    event: { id: string };
    country: string | undefined;
}> = (props) => {
    const {
        isReportThisEventVisible,
        setIsReportThisEventVisible,
        event,
        country,
    } = props;
    return (
        <>
            {isReportThisEventVisible && (
                <LazyReportThisEvent>
                    {({
                        ReportThisEvent,
                    }: {
                        ReportThisEvent: FunctionComponent<ReportThisEventProps>;
                    }) => (
                        <ReportThisEvent
                            domNodeId="listings-root"
                            eventId={event.id}
                            onClose={() => setIsReportThisEventVisible(false)}
                            isVisible={isReportThisEventVisible}
                            country={country}
                        />
                    )}
                </LazyReportThisEvent>
            )}
        </>
    );
};
