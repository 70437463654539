import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { translationPropType } from '@eventbrite/i18n';

import { VectorImage } from '@eventbrite/eds-vector-image';

import { VECTOR_IMAGE_GRAPHIC } from '@eventbrite/eds-vector-image';
import * as constants from './constants';
import './graphic.scss';

export default class Graphic extends React.PureComponent {
    static propTypes = {
        // all props from VectorImage
        ...omit(VectorImage.propTypes, ['svg', 'type']),
        /**
         * The type of icon that you want to display e.g. camera
         */
        type: constants.GRAPHIC_TYPE_PROP_TYPE.isRequired,
        /**
         * The size of the icon
         */
        size: PropTypes.oneOf(constants.GRAPHIC_SIZES),
        /**
         * Add a title attribute to the to graphic markup
         */
        title: translationPropType,
    };

    static defaultProps = {
        size: 'medium',
    };

    render() {
        const { type, size } = this.props;
        const vectorImageProps = pick(
            this.props,
            ...Object.keys(VectorImage.propTypes),
        );

        return (
            <VectorImage
                {...vectorImageProps}
                type={VECTOR_IMAGE_GRAPHIC}
                svg={type}
                size={size}
            />
        );
    }
}
