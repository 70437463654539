import React from 'react';

import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import { numberFormatter } from '../../../utils/utils';

import { TOTAL_RATING_THRESHOLD } from '../../constants';
import { RATING_INFO_TEST_ID } from './test-id.constant';

import StarIcon from './StarIcon';

import ratingInfoStyles from './RatingInfo.module.scss';
import styles from './Ratings.module.scss';

import type { IRatingInfoProps } from './RatingInfo.type';

interface IRatingsProps
    extends Pick<
        IRatingInfoProps,
        'starRating' | 'totalRating' | 'onRatingClick'
    > {
    showCircleAfterRating: boolean;
}

export const Ratings: React.FC<IRatingsProps> = ({
    starRating,
    totalRating,
    showCircleAfterRating,
    onRatingClick,
}) => {
    const _showRatings = totalRating > TOTAL_RATING_THRESHOLD;
    if (!_showRatings) return null;

    return (
        <div
            className={classNames(
                ratingInfoStyles.withTopMargin,
                styles.flexContainer,
            )}
            data-testid={RATING_INFO_TEST_ID.RATINGS_CONTAINER}
        >
            <button
                type="button"
                className={classNames(styles.flexContainer, styles.ctaText)}
                onClick={onRatingClick}
            >
                <StarIcon />
                <span>{starRating.toFixed(1)}</span>
            </button>
            <span className={styles.circle} />
            <button
                type="button"
                className={styles.ctaText}
                onClick={onRatingClick}
            >
                <span>
                    {numberFormatter(totalRating)} {gettext('ratings')}
                </span>
            </button>
            <span
                className={classNames({
                    [styles.circle]: showCircleAfterRating,
                })}
            />
        </div>
    );
};
