import { GenericLazyString, gettext } from '@eventbrite/i18n';
import arts from '../static/images/categories/arts.webp';
import auto from '../static/images/categories/auto.webp';
import business from '../static/images/categories/business.webp';
import charity_and_causes from '../static/images/categories/charity_and_causes.webp';
import community from '../static/images/categories/community.webp';
import family from '../static/images/categories/family.webp';
import fashion from '../static/images/categories/fashion.webp';
import film_and_media from '../static/images/categories/film_and_media.webp';
import food from '../static/images/categories/food.webp';
import government from '../static/images/categories/government.webp';
import health from '../static/images/categories/health.webp';
import hobbies from '../static/images/categories/hobbies.webp';
import holiday from '../static/images/categories/holiday.webp';
import home from '../static/images/categories/home.webp';
import music from '../static/images/categories/music.webp';
import nightlife from '../static/images/categories/nightlife.webp';
import school from '../static/images/categories/school.webp';
import science from '../static/images/categories/science.webp';
import arts_small from '../static/images/categories/small/arts.webp';
import auto_small from '../static/images/categories/small/auto.webp';
import business_small from '../static/images/categories/small/business.webp';
import charity_and_causes_small from '../static/images/categories/small/charity_and_causes.webp';
import community_small from '../static/images/categories/small/community.webp';
import family_small from '../static/images/categories/small/family.webp';
import fashion_small from '../static/images/categories/small/fashion.webp';
import film_and_media_small from '../static/images/categories/small/film_and_media.webp';
import food_small from '../static/images/categories/small/food.webp';
import government_small from '../static/images/categories/small/government.webp';
import health_small from '../static/images/categories/small/health.webp';
import hobbies_small from '../static/images/categories/small/hobbies.webp';
import holiday_small from '../static/images/categories/small/holiday.webp';
import home_small from '../static/images/categories/small/home.webp';
import music_small from '../static/images/categories/small/music.webp';
import nightlife_small from '../static/images/categories/small/nightlife.webp';
import school_small from '../static/images/categories/small/school.webp';
import science_small from '../static/images/categories/small/science.webp';
import spirituality_small from '../static/images/categories/small/spirituality.webp';
import sports_small from '../static/images/categories/small/sports.webp';
import travel_small from '../static/images/categories/small/travel.webp';
import spirituality from '../static/images/categories/spirituality.webp';
import sports from '../static/images/categories/sports.webp';
import travel from '../static/images/categories/travel.webp';

//Eventbrite Category ID Mapping
export const BUSINESS = 101;
export const SCIENCE = 102;
export const MUSIC = 103;
export const FILM = 104;
export const PERFORMING = 105;
export const FASHION = 106;
export const HEALTH = 107;
export const SPORTS = 108;
export const TRAVEL = 109;
export const FOOD = 110;
export const CHARITY = 111;
export const POLITICS = 112;
export const COMMUNITY = 113;
export const RELIGION = 114;
export const FAMILY_EDUCATION = 115;
export const SEASONAL = 116;
export const HOME_LIFESTYLE = 117;
export const AUTO_BOAT_AIR = 118;
export const HOBBIES = 119;
export const SCHOOL = 120;
export const NIGHTLIFE = 121;
export const OTHER = 199;

export const EVENTBRITE_CATEGORY = 'EventbriteCategory';
export const BUSINESS_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${BUSINESS}`;
export const SCIENCE_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SCIENCE}`;
export const MUSIC_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${MUSIC}`;
export const FILM_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FILM}`;
export const PERFORMING_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${PERFORMING}`;
export const FASHION_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FASHION}`;
export const HEALTH_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${HEALTH}`;
export const SPORTS_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SPORTS}`;
export const TRAVEL_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${TRAVEL}`;
export const FOOD_AND_DRINK_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FOOD}`;
export const CHARITY_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${CHARITY}`;
export const POLITICS_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${POLITICS}`;
export const COMMUNITY_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${COMMUNITY}`;
export const RELIGION_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${RELIGION}`;
export const FAMILY_EDUCATION_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FAMILY_EDUCATION}`;
export const SEASONAL_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SEASONAL}`;
export const HOME_LIFESTYLE_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${HOME_LIFESTYLE}`;
export const AUTO_BOAT_AIR_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${AUTO_BOAT_AIR}`;
export const HOBBIES_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${HOBBIES}`;
export const SCHOOL_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SCHOOL}`;
export const OTHER_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${OTHER}`;
export const NIGHTLIFE_EVENTBRITE_CATEGORY = `Destination/nightlife`;
export const ACTIVE_SEASONAL_EVENTBRITE_CATEGORY = `Destination/seasonal`;
const DATING_EVENTBRITE_SUB_CATEGORY = `EventbriteSubCategory/17001`;

export const EVENTBRITE_CATEGORY_ID_STRINGS = [
    BUSINESS_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    RELIGION_EVENTBRITE_CATEGORY,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    SCHOOL_EVENTBRITE_CATEGORY,
    NIGHTLIFE_EVENTBRITE_CATEGORY,
    ACTIVE_SEASONAL_EVENTBRITE_CATEGORY,
    OTHER_EVENTBRITE_CATEGORY,
];

export const EVENTBRITE_CATEGORY_IMAGES: { [key: string]: string } = {
    [BUSINESS_EVENTBRITE_CATEGORY]: business,
    [SCIENCE_EVENTBRITE_CATEGORY]: science,
    [MUSIC_EVENTBRITE_CATEGORY]: music,
    [FILM_EVENTBRITE_CATEGORY]: film_and_media,
    [PERFORMING_EVENTBRITE_CATEGORY]: arts,
    [FASHION_EVENTBRITE_CATEGORY]: fashion,
    [HEALTH_EVENTBRITE_CATEGORY]: health,
    [SPORTS_EVENTBRITE_CATEGORY]: sports,
    [TRAVEL_EVENTBRITE_CATEGORY]: travel,
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: food,
    [CHARITY_EVENTBRITE_CATEGORY]: charity_and_causes,
    [POLITICS_EVENTBRITE_CATEGORY]: government,
    [COMMUNITY_EVENTBRITE_CATEGORY]: community,
    [RELIGION_EVENTBRITE_CATEGORY]: spirituality,
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: family,
    [SEASONAL_EVENTBRITE_CATEGORY]: holiday,
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: home,
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: auto,
    [HOBBIES_EVENTBRITE_CATEGORY]: hobbies,
    [SCHOOL_EVENTBRITE_CATEGORY]: school,
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: nightlife,
    [OTHER_EVENTBRITE_CATEGORY]: travel,
};
export const EVENTBRITE_CATEGORY_IMAGES_SMALL = {
    [BUSINESS_EVENTBRITE_CATEGORY]: business_small,
    [SCIENCE_EVENTBRITE_CATEGORY]: science_small,
    [MUSIC_EVENTBRITE_CATEGORY]: music_small,
    [FILM_EVENTBRITE_CATEGORY]: film_and_media_small,
    [PERFORMING_EVENTBRITE_CATEGORY]: arts_small,
    [FASHION_EVENTBRITE_CATEGORY]: fashion_small,
    [HEALTH_EVENTBRITE_CATEGORY]: health_small,
    [SPORTS_EVENTBRITE_CATEGORY]: sports_small,
    [TRAVEL_EVENTBRITE_CATEGORY]: travel_small,
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: food_small,
    [CHARITY_EVENTBRITE_CATEGORY]: charity_and_causes_small,
    [POLITICS_EVENTBRITE_CATEGORY]: government_small,
    [COMMUNITY_EVENTBRITE_CATEGORY]: community_small,
    [RELIGION_EVENTBRITE_CATEGORY]: spirituality_small,
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: family_small,
    [SEASONAL_EVENTBRITE_CATEGORY]: holiday_small,
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: home_small,
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: auto_small,
    [HOBBIES_EVENTBRITE_CATEGORY]: hobbies_small,
    [SCHOOL_EVENTBRITE_CATEGORY]: school_small,
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: nightlife_small,
    [OTHER_EVENTBRITE_CATEGORY]: travel_small,
};

/* To-do: change name of the seasonal page constant and add images (normal/small) for seasonal tag */

// FIXME: Types [K in typeof EVENTBRITE_CATEGORY_ID_STRINGS[number]]: string;
export const EVENTBRITE_CATEGORY_TO_REFINEMENT: { [key: string]: string } = {
    [BUSINESS_EVENTBRITE_CATEGORY]: 'business',
    [SCIENCE_EVENTBRITE_CATEGORY]: 'science-and-tech',
    [MUSIC_EVENTBRITE_CATEGORY]: 'music',
    [FILM_EVENTBRITE_CATEGORY]: 'film-and-media',
    [PERFORMING_EVENTBRITE_CATEGORY]: 'arts',
    [FASHION_EVENTBRITE_CATEGORY]: 'fashion',
    [HEALTH_EVENTBRITE_CATEGORY]: 'health',
    [SPORTS_EVENTBRITE_CATEGORY]: 'sports-and-fitness',
    [TRAVEL_EVENTBRITE_CATEGORY]: 'travel-and-outdoor',
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: 'food-and-drink',
    [CHARITY_EVENTBRITE_CATEGORY]: 'charity-and-causes',
    [POLITICS_EVENTBRITE_CATEGORY]: 'government',
    [COMMUNITY_EVENTBRITE_CATEGORY]: 'community',
    [RELIGION_EVENTBRITE_CATEGORY]: 'spirituality',
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: 'family-and-education',
    [SEASONAL_EVENTBRITE_CATEGORY]: 'holiday',
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: 'home-and-lifestyle',
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: 'auto-boat-and-air',
    [HOBBIES_EVENTBRITE_CATEGORY]: 'hobbies',
    [SCHOOL_EVENTBRITE_CATEGORY]: 'school-activities',
    [OTHER_EVENTBRITE_CATEGORY]: 'other',
    [NIGHTLIFE_EVENTBRITE_CATEGORY]: 'nightlife',
    [ACTIVE_SEASONAL_EVENTBRITE_CATEGORY]: 'seasonal',
    [DATING_EVENTBRITE_SUB_CATEGORY]: 'dating',
};

export const EVENTBRITE_REFINEMENT_TO_CATEGORY: Record<string, string> = {
    business: BUSINESS_EVENTBRITE_CATEGORY,
    'science-and-tech': SCIENCE_EVENTBRITE_CATEGORY,
    music: MUSIC_EVENTBRITE_CATEGORY,
    nightlife: NIGHTLIFE_EVENTBRITE_CATEGORY,
    'film-and-media': FILM_EVENTBRITE_CATEGORY,
    arts: PERFORMING_EVENTBRITE_CATEGORY,
    fashion: FASHION_EVENTBRITE_CATEGORY,
    health: HEALTH_EVENTBRITE_CATEGORY,
    'sports-and-fitness': SPORTS_EVENTBRITE_CATEGORY,
    'travel-and-outdoor': TRAVEL_EVENTBRITE_CATEGORY,
    'food-and-drink': FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    'charity-and-causes': CHARITY_EVENTBRITE_CATEGORY,
    government: POLITICS_EVENTBRITE_CATEGORY,
    community: COMMUNITY_EVENTBRITE_CATEGORY,
    spirituality: RELIGION_EVENTBRITE_CATEGORY,
    'family-and-education': FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    holiday: SEASONAL_EVENTBRITE_CATEGORY,
    'home-and-lifestyle': HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    'auto-boat-and-air': AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    hobbies: HOBBIES_EVENTBRITE_CATEGORY,
    'school-activities': SCHOOL_EVENTBRITE_CATEGORY,
    seasonal: ACTIVE_SEASONAL_EVENTBRITE_CATEGORY,
    other: OTHER_EVENTBRITE_CATEGORY,
};

// FIXME Types [K in typeof EVENTBRITE_CATEGORY_ID_STRINGS[number]]: GenericLazyString;
export const EVENTBRITE_CATEGORY_MAPPING: { [key: string]: GenericLazyString } =
    {
        [BUSINESS_EVENTBRITE_CATEGORY]: gettext('Business'),
        [SCIENCE_EVENTBRITE_CATEGORY]: gettext('Science & Tech'),
        [MUSIC_EVENTBRITE_CATEGORY]: gettext('Music'),
        [NIGHTLIFE_EVENTBRITE_CATEGORY]: gettext('Nightlife'),
        [ACTIVE_SEASONAL_EVENTBRITE_CATEGORY]: gettext('Seasonal'),
        [DATING_EVENTBRITE_SUB_CATEGORY]: gettext('Dating'),
        [FILM_EVENTBRITE_CATEGORY]: gettext('Film & Media'),
        [PERFORMING_EVENTBRITE_CATEGORY]: gettext('Performing & Visual Arts'),
        [FASHION_EVENTBRITE_CATEGORY]: gettext('Fashion'),
        [HEALTH_EVENTBRITE_CATEGORY]: gettext('Health'),
        [SPORTS_EVENTBRITE_CATEGORY]: gettext('Sports & Fitness'),
        [TRAVEL_EVENTBRITE_CATEGORY]: gettext('Travel & Outdoor'),
        [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: gettext('Food & Drink'),
        [CHARITY_EVENTBRITE_CATEGORY]: gettext('Charity & Causes'),
        [POLITICS_EVENTBRITE_CATEGORY]: gettext('Government'),
        [COMMUNITY_EVENTBRITE_CATEGORY]: gettext('Community'),
        [RELIGION_EVENTBRITE_CATEGORY]: gettext('Spirituality'),
        [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: gettext('Family & Education'),
        [SEASONAL_EVENTBRITE_CATEGORY]: gettext('Holidays'),
        [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: gettext('Home & Lifestyle'),
        [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: gettext('Auto, Boat & Air'),
        [HOBBIES_EVENTBRITE_CATEGORY]: gettext('Hobbies'),
        [SCHOOL_EVENTBRITE_CATEGORY]: gettext('School Activities'),
        [OTHER_EVENTBRITE_CATEGORY]: gettext('Other'),
    };
