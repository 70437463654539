import { updatePageProps } from '@eventbrite/datalayer-library';
import {
    OnlyWhenUrgencySignalsEnabled,
    UrgencyCategories,
    UrgencyMessages,
    UrgencySignalsProps,
    UrgencySignalsProvider,
} from '@eventbrite/urgency-signals';
import React, { useEffect, useState } from 'react';
import { fetchEventUrgencySignals } from '../../api';
import {
    EventActionType,
    useEventBasicInformationContext,
    useEventDispatchContext,
} from '../../contexts';
import { getHeapUpdatePropsObject } from '../../tracking/dataLayerMiddleware';

const hasUrgencySignalsToShow = (
    urgencySignalsResponse: UrgencySignalsProps,
): Boolean => {
    if (
        urgencySignalsResponse == undefined ||
        Object.keys(urgencySignalsResponse).length == 0
    ) {
        return false;
    }
    const messagesValues = Object.values(urgencySignalsResponse.messages);
    const categoriesValues = Object.values(urgencySignalsResponse.categories);
    return (
        messagesValues.some((value) => value) ||
        categoriesValues.some((value) => value)
    );
};

export const UrgencySignals: React.FC<{
    urgencySignals: any;
}> = ({ urgencySignals }) => {
    const dispatch = useEventDispatchContext();
    const eventBasicInformation = useEventBasicInformationContext();

    const [urgencySignalsUpdated, setUrgencySignalsUpdated] = useState({
        messages: { ...urgencySignals?.messages },
        categories: {
            ...urgencySignals?.categories,
            earlyBird: false,
        },
    });

    const { id, isSeriesParent, childEvents } = eventBasicInformation;

    const isSeriesEvent = childEvents && childEvents.length > 0;

    useEffect(() => {
        if (isSeriesParent || isSeriesEvent) {
            fetchEventUrgencySignals(id)
                .then((urgencySignalsResponse) => {
                    if (urgencySignalsResponse) {
                        setUrgencySignalsUpdated({
                            categories: {
                                ...urgencySignalsResponse.categories,
                                earlyBird: false,
                            },
                            messages: urgencySignalsResponse.messages,
                        });
                        dispatch({
                            type: EventActionType.setEarlyBird,
                            payload: {
                                hasEarlyBirdTicketsValue:
                                    urgencySignalsResponse.categories.earlyBird,
                            },
                        });
                        if (
                            hasUrgencySignalsToShow({
                                categories: {
                                    ...urgencySignalsResponse.categories,
                                    earlyBird: false,
                                },
                                messages: urgencySignalsResponse.messages,
                            }) &&
                            getHeapUpdatePropsObject() != null
                        ) {
                            const propsObject = getHeapUpdatePropsObject();
                            updatePageProps({
                                page: {
                                    ...propsObject,
                                    hasUrgencySignals: true,
                                },
                            });
                        }
                    }
                })
                .catch((error: any) => {
                    throw error.parsedError || {};
                });
        }
    }, [id, isSeriesParent, isSeriesEvent, dispatch]);

    return (
        <UrgencySignalsProvider urgencySignals={urgencySignalsUpdated}>
            <OnlyWhenUrgencySignalsEnabled>
                <div className="urgency-signals">
                    <UrgencyMessages />
                    <UrgencyCategories />
                </div>
            </OnlyWhenUrgencySignalsEnabled>
        </UrgencySignalsProvider>
    );
};
