export const countryMapping: {
    [key: string]: { currency: string };
} = {
    Argentina: { currency: 'ARS' },
    Australia: { currency: 'AUD' },
    Austria: { currency: 'EUR' },
    Belgium: { currency: 'EUR' },
    Brazil: { currency: 'BRL' },
    Canada: { currency: 'CAD' },
    Chile: { currency: 'USD' },
    Colombia: { currency: 'USD' },
    Cyprus: { currency: 'EUR' },
    'Czech Republic': { currency: 'CZK' },
    Denmark: { currency: 'DKK' },
    Estonia: { currency: 'EUR' },
    Finland: { currency: 'EUR' },
    France: { currency: 'EUR' },
    Germany: { currency: 'EUR' },
    Greece: { currency: 'EUR' },
    'Hong Kong': { currency: 'HKD' },
    Hungary: { currency: 'HUF' },
    Ireland: { currency: 'EUR' },
    Israel: { currency: 'ILS' },
    Italy: { currency: 'EUR' },
    Japan: { currency: 'JPY' },
    Latvia: { currency: 'EUR' },
    Lithuania: { currency: 'EUR' },
    Luxembourg: { currency: 'EUR' },
    Malaysia: { currency: 'MYR' },
    Malta: { currency: 'EUR' },
    Mexico: { currency: 'MXN' },
    Netherlands: { currency: 'EUR' },
    'New Zealand': { currency: 'NZD' },
    Norway: { currency: 'NOK' },
    Peru: { currency: 'USD' },
    Philippines: { currency: 'PHP' },
    Poland: { currency: 'PLN' },
    Portugal: { currency: 'EUR' },
    Singapore: { currency: 'SGD' },
    Slovakia: { currency: 'EUR' },
    Slovenia: { currency: 'EUR' },
    Spain: { currency: 'EUR' },
    Sweden: { currency: 'SEK' },
    Switzerland: { currency: 'CHF' },
    Taiwan: { currency: 'TWD' },
    Thailand: { currency: 'THB' },
    'United Kingdom': { currency: 'GBP' },
    'United States': { currency: 'USD' },
};
