import React from 'react';

import { RatingSvg } from './RatingSvg';

import type { IRatingMeta } from '../../../../../RelatedEvents/types/api';

export const getOrganizerRatingSvg = (overallRating: number) => {
    const ratingsSvg = new RatingSvg(overallRating);
    const clipPath = ratingsSvg.getClipPath();
    const starPaths = ratingsSvg.getStarPathsJoined();
    return (
        <svg
            width="89"
            height="18"
            viewBox="0 0 89 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `${clipPath}${starPaths}` }}
        />
    );
};

export const getOrganizerRatingsDistributionList = (
    overallRatings: IRatingMeta,
) => {
    const { total, count_5, count_4, count_3, count_2, count_1 } =
        overallRatings;
    return [
        { number: 5, value: (+count_5 / +total) * 100 },
        { number: 4, value: (+count_4 / +total) * 100 },
        { number: 3, value: (+count_3 / +total) * 100 },
        { number: 2, value: (+count_2 / +total) * 100 },
        { number: 1, value: (+count_1 / +total) * 100 },
    ];
};
