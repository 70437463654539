import styles from './OrganizerRating.module.scss';

export class RatingSvg {
    //#region variables
    _starIndex = 0;
    _STAR_WIDTH = 13.2;
    _COLORS = {
        ORANGE: '#F05537',
        SILVER: '#C1BDC7',
    };
    _clipPath = `<defs><clipPath id="clip"><rect id="clipRect" x="0" y="0" width="0" height="18"></rect></clipPath></defs>`;
    _starPaths = [
        `<path d="M8.91198 3.22431C9.02685 2.87213 9.52509 2.87213 9.63997 3.22431L10.9071 7.109C10.9585 7.26654 11.1054 7.37314 11.2711 7.37314H15.3684C15.7397 7.37314 15.8936 7.84851 15.5929 8.06617L12.2806 10.4633C12.1459 10.5608 12.0896 10.7341 12.1411 10.8922L13.4069 14.7728C13.5219 15.1252 13.1188 15.419 12.8184 15.2016L9.50044 12.8003C9.3665 12.7034 9.18545 12.7034 9.0515 12.8003L5.73352 15.2016C5.43317 15.419 5.03008 15.1252 5.14505 14.7728L6.41082 10.8922C6.46239 10.7341 6.40601 10.5608 6.2713 10.4633L2.95905 8.06617C2.6583 7.84851 2.81227 7.37314 3.18353 7.37314H7.28086C7.44657 7.37314 7.59347 7.26654 7.64485 7.109L8.91198 3.22431Z" fill="${this._COLORS.SILVER}"/>`,
        `<path d="M26.524 3.22431C26.6389 2.87213 27.1371 2.87213 27.252 3.22431L28.5192 7.109C28.5705 7.26654 28.7174 7.37314 28.8831 7.37314H32.9805C33.3517 7.37314 33.5057 7.84851 33.205 8.06617L29.8927 10.4633C29.758 10.5608 29.7016 10.7341 29.7532 10.8922L31.019 14.7728C31.1339 15.1252 30.7308 15.419 30.4305 15.2016L27.1125 12.8003C26.9786 12.7034 26.7975 12.7034 26.6636 12.8003L23.3456 15.2016C23.0452 15.419 22.6421 15.1252 22.7571 14.7728L24.0229 10.8922C24.0745 10.7341 24.0181 10.5608 23.8834 10.4633L20.5711 8.06617C20.2704 7.84851 20.4243 7.37314 20.7956 7.37314H24.8929C25.0586 7.37314 25.2055 7.26654 25.2569 7.109L26.524 3.22431Z" fill="${this._COLORS.SILVER}"/>`,
        `<path d="M44.1359 3.22431C44.2507 2.87213 44.749 2.87213 44.8638 3.22431L46.131 7.109C46.1824 7.26654 46.3293 7.37314 46.495 7.37314H50.5923C50.9636 7.37314 51.1175 7.84851 50.8168 8.06617L47.5045 10.4633C47.3698 10.5608 47.3134 10.7341 47.365 10.8922L48.6308 14.7728C48.7457 15.1252 48.3427 15.419 48.0423 15.2016L44.7243 12.8003C44.5904 12.7034 44.4093 12.7034 44.2754 12.8003L40.9574 15.2016C40.657 15.419 40.254 15.1252 40.3689 14.7728L41.6347 10.8922C41.6863 10.7341 41.6299 10.5608 41.4952 10.4633L38.1829 8.06617C37.8822 7.84851 38.0361 7.37314 38.4074 7.37314H42.5047C42.6704 7.37314 42.8173 7.26654 42.8687 7.109L44.1359 3.22431Z" fill="${this._COLORS.SILVER}"/>`,
        `<path d="M61.7479 3.22431C61.8628 2.87213 62.361 2.87213 62.4759 3.22431L63.743 7.109C63.7944 7.26654 63.9413 7.37314 64.107 7.37314H68.2044C68.5756 7.37314 68.7296 7.84851 68.4288 8.06617L65.1166 10.4633C64.9819 10.5608 64.9255 10.7341 64.9771 10.8922L66.2428 14.7728C66.3578 15.1252 65.9547 15.419 65.6544 15.2016L62.3364 12.8003C62.2024 12.7034 62.0214 12.7034 61.8874 12.8003L58.5695 15.2016C58.2691 15.419 57.866 15.1252 57.981 14.7728L59.2468 10.8922C59.2983 10.7341 59.242 10.5608 59.1072 10.4633L55.795 8.06617C55.4942 7.84851 55.6482 7.37314 56.0195 7.37314H60.1168C60.2825 7.37314 60.4294 7.26654 60.4808 7.109L61.7479 3.22431Z" fill="${this._COLORS.SILVER}"/>`,
        `<path d="M79.3595 3.22431C79.4744 2.87213 79.9726 2.87213 80.0875 3.22431L81.3546 7.109C81.406 7.26654 81.5529 7.37314 81.7186 7.37314H85.8159C86.1872 7.37314 86.3412 7.84851 86.0404 8.06617L82.7282 10.4633C82.5934 10.5608 82.5371 10.7341 82.5886 10.8922L83.8544 14.7728C83.9694 15.1252 83.5663 15.419 83.2659 15.2016L79.948 12.8003C79.814 12.7034 79.633 12.7034 79.499 12.8003L76.181 15.2016C75.8807 15.419 75.4776 15.1252 75.5926 14.7728L76.8583 10.8922C76.9099 10.7341 76.8535 10.5608 76.7188 10.4633L73.4066 8.06617C73.1058 7.84851 73.2598 7.37314 73.631 7.37314H77.7284C77.8941 7.37314 78.041 7.26654 78.0924 7.109L79.3595 3.22431Z" fill="${this._COLORS.SILVER}"/>`,
    ];
    _filledPaths = [
        `<path clip-path="url(#clip)" class="${styles.filled}" d="M8.91198 3.22431C9.02685 2.87213 9.52509 2.87213 9.63997 3.22431L10.9071 7.109C10.9585 7.26654 11.1054 7.37314 11.2711 7.37314H15.3684C15.7397 7.37314 15.8936 7.84851 15.5929 8.06617L12.2806 10.4633C12.1459 10.5608 12.0896 10.7341 12.1411 10.8922L13.4069 14.7728C13.5219 15.1252 13.1188 15.419 12.8184 15.2016L9.50044 12.8003C9.3665 12.7034 9.18545 12.7034 9.0515 12.8003L5.73352 15.2016C5.43317 15.419 5.03008 15.1252 5.14505 14.7728L6.41082 10.8922C6.46239 10.7341 6.40601 10.5608 6.2713 10.4633L2.95905 8.06617C2.6583 7.84851 2.81227 7.37314 3.18353 7.37314H7.28086C7.44657 7.37314 7.59347 7.26654 7.64485 7.109L8.91198 3.22431Z" />`,
        `<path clip-path="url(#clip)" class="${styles.filled}" d="M26.524 3.22431C26.6389 2.87213 27.1371 2.87213 27.252 3.22431L28.5192 7.109C28.5705 7.26654 28.7174 7.37314 28.8831 7.37314H32.9805C33.3517 7.37314 33.5057 7.84851 33.205 8.06617L29.8927 10.4633C29.758 10.5608 29.7016 10.7341 29.7532 10.8922L31.019 14.7728C31.1339 15.1252 30.7308 15.419 30.4305 15.2016L27.1125 12.8003C26.9786 12.7034 26.7975 12.7034 26.6636 12.8003L23.3456 15.2016C23.0452 15.419 22.6421 15.1252 22.7571 14.7728L24.0229 10.8922C24.0745 10.7341 24.0181 10.5608 23.8834 10.4633L20.5711 8.06617C20.2704 7.84851 20.4243 7.37314 20.7956 7.37314H24.8929C25.0586 7.37314 25.2055 7.26654 25.2569 7.109L26.524 3.22431Z" />`,
        `<path clip-path="url(#clip)" class="${styles.filled}" d="M44.1359 3.22431C44.2507 2.87213 44.749 2.87213 44.8638 3.22431L46.131 7.109C46.1824 7.26654 46.3293 7.37314 46.495 7.37314H50.5923C50.9636 7.37314 51.1175 7.84851 50.8168 8.06617L47.5045 10.4633C47.3698 10.5608 47.3134 10.7341 47.365 10.8922L48.6308 14.7728C48.7457 15.1252 48.3427 15.419 48.0423 15.2016L44.7243 12.8003C44.5904 12.7034 44.4093 12.7034 44.2754 12.8003L40.9574 15.2016C40.657 15.419 40.254 15.1252 40.3689 14.7728L41.6347 10.8922C41.6863 10.7341 41.6299 10.5608 41.4952 10.4633L38.1829 8.06617C37.8822 7.84851 38.0361 7.37314 38.4074 7.37314H42.5047C42.6704 7.37314 42.8173 7.26654 42.8687 7.109L44.1359 3.22431Z" />`,
        `<path clip-path="url(#clip)" class="${styles.filled}" d="M61.7479 3.22431C61.8628 2.87213 62.361 2.87213 62.4759 3.22431L63.743 7.109C63.7944 7.26654 63.9413 7.37314 64.107 7.37314H68.2044C68.5756 7.37314 68.7296 7.84851 68.4288 8.06617L65.1166 10.4633C64.9819 10.5608 64.9255 10.7341 64.9771 10.8922L66.2428 14.7728C66.3578 15.1252 65.9547 15.419 65.6544 15.2016L62.3364 12.8003C62.2024 12.7034 62.0214 12.7034 61.8874 12.8003L58.5695 15.2016C58.2691 15.419 57.866 15.1252 57.981 14.7728L59.2468 10.8922C59.2983 10.7341 59.242 10.5608 59.1072 10.4633L55.795 8.06617C55.4942 7.84851 55.6482 7.37314 56.0195 7.37314H60.1168C60.2825 7.37314 60.4294 7.26654 60.4808 7.109L61.7479 3.22431Z" />`,
        `<path clip-path="url(#clip)" class="${styles.filled}" d="M79.3595 3.22431C79.4744 2.87213 79.9726 2.87213 80.0875 3.22431L81.3546 7.109C81.406 7.26654 81.5529 7.37314 81.7186 7.37314H85.8159C86.1872 7.37314 86.3412 7.84851 86.0404 8.06617L82.7282 10.4633C82.5934 10.5608 82.5371 10.7341 82.5886 10.8922L83.8544 14.7728C83.9694 15.1252 83.5663 15.419 83.2659 15.2016L79.948 12.8003C79.814 12.7034 79.633 12.7034 79.499 12.8003L76.181 15.2016C75.8807 15.419 75.4776 15.1252 75.5926 14.7728L76.8583 10.8922C76.9099 10.7341 76.8535 10.5608 76.7188 10.4633L73.4066 8.06617C73.1058 7.84851 73.2598 7.37314 73.631 7.37314H77.7284C77.8941 7.37314 78.041 7.26654 78.0924 7.109L79.3595 3.22431Z" />`,
    ];
    //#endregion

    constructor(overallRating: number) {
        const [integer, decimal] = overallRating.toFixed(1).split('.');
        this._fillStarsForIntegerValue(+integer);
        this._fillStarForDecimalValue(+decimal);
    }

    _getSanitizedInteger = (integer: number) => {
        return Math.min(integer, this._starPaths.length); // if integer > 5, return 5
    };

    _fillStarsForIntegerValue = (integer: number) => {
        integer = this._getSanitizedInteger(integer);
        for (this._starIndex; this._starIndex < integer; this._starIndex++) {
            this._starPaths[this._starIndex] = this._starPaths[
                this._starIndex
            ].replace(
                `fill="${this._COLORS.SILVER}"`,
                `fill="${this._COLORS.ORANGE}"`,
            );
        }
    };

    _shouldFillStarForDecimalValue = (decimal: number) => {
        return +decimal && this._starIndex < this._starPaths.length;
    };

    _getClipPathWidth = (decimal: number) => {
        return this._STAR_WIDTH * (decimal * 0.1);
    };

    // prettier-ignore
    _getClipPathXPosition = () => {
        const starPath = (this._starPaths[this._starIndex].match(/d="([^"]+)"/) || [])[1];
        const starMiddlePointMovePosition = starPath.split(' ').shift() ?? 'M8.91198'; // ?? 'M8.91198' = first move position of starPath; check starPaths[0] d="M8.91198 ..."
        const starMiddleXPoint = Number(starMiddlePointMovePosition.substring(1));
        return starMiddleXPoint - 6.2537; // 6.2537 = horizontal distance of middle point and start point of the star
    };

    // prettier-ignore
    _fillStarForDecimalValue = (decimal: number) => {
        if (!this._shouldFillStarForDecimalValue(decimal)) return;
        this._starPaths.splice(this._starIndex + 1, 0, this._filledPaths[this._starIndex]); // add star path for showing partially filled star
        const width = this._getClipPathWidth(decimal);
        const xPosition = this._getClipPathXPosition();
        this._clipPath = this._clipPath.replace('width="0"', `width="${width}"`);
        this._clipPath = this._clipPath.replace('x="0"', `x="${xPosition}"`);
    };

    getClipPath = () => {
        return this._clipPath;
    };

    getStarPathsJoined = () => {
        return this._starPaths.join('');
    };
}
