import styled from '@emotion/styled';
import React from 'react';
import { CarouselItems } from '../HeroCarousel';

export type HeroImageProps = {
    alt?: string;
    fetchPriority?: FetchPriorityType;
    loading?: LoadingType;
} & Pick<
    CarouselItems,
    'croppedLogoUrl480' | 'croppedLogoUrl600' | 'croppedLogoUrl940'
>;

export enum LoadingType {
    eager = 'eager',
    lazy = 'lazy',
}

export enum FetchPriorityType {
    high = 'high',
    low = 'low',
}

export const HeroImage = ({
    alt,
    croppedLogoUrl480,
    croppedLogoUrl600,
    croppedLogoUrl940,
    fetchPriority,
    loading,
}: HeroImageProps) => {
    //TODO: Refactor block (Lines 32-57) by requesting the event image to the new image service endpoint.
    const hasFocalPoint =
        (croppedLogoUrl480 && croppedLogoUrl480.includes('fp-x=')) ||
        (croppedLogoUrl600 && croppedLogoUrl600.includes('fp-x=')) ||
        (croppedLogoUrl940 && croppedLogoUrl940.includes('fp-x='));

    // Set a default focal point just to be safe
    let focalPoint = { x: 0.5, y: 0.5 };

    if (hasFocalPoint) {
        const imageUrl = croppedLogoUrl480
            ? new URL(croppedLogoUrl480)
            : croppedLogoUrl600
            ? new URL(croppedLogoUrl600)
            : croppedLogoUrl940
            ? new URL(croppedLogoUrl940)
            : '';

        if (imageUrl !== '') {
            focalPoint = {
                x: parseFloat(imageUrl.searchParams.get('fp-x') || '0.5'),
                y: parseFloat(imageUrl.searchParams.get('fp-y') || '0.5'),
            };
        }
    }

    return (
        <picture data-testid="hero-image">
            <source
                srcSet={`
                    ${croppedLogoUrl480} 480w,
                    ${croppedLogoUrl600} 600w,
                    ${croppedLogoUrl940} 940w
                `}
                sizes="
                    (max-width:480px) 480px,
                    (max-width:600px) 600px,
                    940px
                "
            />
            {hasFocalPoint ? (
                <Image
                    alt={alt}
                    src={croppedLogoUrl600}
                    loading={loading}
                    // fetchpriority does not exist on type 'DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>'
                    //eslint-disable-next-line
                    //@ts-ignore
                    fetchpriority={fetchPriority}
                    x={focalPoint.x}
                    y={focalPoint.y}
                    data-testid="hero-img"
                />
            ) : (
                <img
                    width="600"
                    height="300"
                    alt={alt}
                    src={croppedLogoUrl600}
                    loading={loading}
                    // fetchpriority does not exist on type 'DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>'
                    //eslint-disable-next-line
                    //@ts-ignore
                    fetchpriority={fetchPriority}
                    data-testid="hero-img"
                />
            )}
        </picture>
    );
};

const Image = styled.img<{ x: number; y: number }>`
    user-select: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    object-position: ${({ x, y }) => `${x * 100}% ${y * 100}%`};
    aspect-ratio: 2/1;
`;
