import React, { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { MarmaladeBase } from '@eventbrite/marmalade/lib/types';

import { BREAKPOINT_SIZES, CONTROL_POSITIONS } from './constants';
import { SegmentedCarouselContext } from './context';
import {
    DefaultsProps,
    getRootBreakpointDefaults,
    initDefaults,
} from './defaults';
import styles from './SegmentedCarousel.module.scss';
import {
    SegmentedCarouselChips,
    SegmentedCarouselChipsProps,
} from './SegmentedCarouselChips';
import {
    SegmentedCarouselFooter,
    SegmentedCarouselFooterProps,
} from './SegmentedCarouselFooter';
import {
    SegmentedCarouselHeader,
    SegmentedCarouselHeaderProps,
} from './SegmentedCarouselHeader';
import {
    SegmentedCarouselTrack,
    SegmentedCarouselTrackProps,
} from './SegmentedCarouselTrack';

export interface SegmentedCarouselProps extends MarmaladeBase {
    children: React.ReactNode;
    /**
     * Stop segments from overflowing the inner container
     */
    hideOverflow?: boolean;
    /**
     * Location of the controls. By default, controls
     * will be in the footer on containers that are 320px or less.
     */
    controlPosition?: 'top' | 'bottom' | 'hidden';
    /**
     * Number of visible segments per slide.
     * Default is based on container width:
     * 320px = 1
     * 640px = 2
     * 960px = 3
     * 1280px = 4
     */
    visibleSegments?: number;
    /**
     * If 'true', the carousel slides to next set of visible segments.
     * If 'false', the carousel slides one segment at a time, while
     * keeping the correct number of visible segments
     */
    shouldPaginate?: boolean;
    /**
     * Internal padding (separate from the root padding) for all containers
     * within the root container: Header, Footer, Track, Segments.
     * The purpose of this padding is to provide uniformity across all
     * containers with one padding value.
     */
    syncedPadding?: number;
    /**
     * Top padding between root wrapper and inner containers
     */
    rootPaddingTop?: number;
    /**
     * Right padding between root wrapper and inner containers
     */
    rootPaddingRight?: number;
    /**
     * Bottom padding between root wrapper and inner containers
     */
    rootPaddingBottom?: number;
    /**
     * Left padding between root wrapper and inner containers
     */
    rootPaddingLeft?: number;
    /**
     * Space between header and carousel track
     */
    headerGap?: number;
    /**
     * Space between footer and carousel track
     */
    footerGap?: number;

    // useSegmentedCarouselController props:
    /**
     * Prop of useSegmentedCarouselController.
     * Slide direction
     */
    slideTo: 'next' | 'previous' | '';
    /**
     * Prop of useSegmentedCarouselController.
     * Sets the slide direction
     */
    setSlideTo: (state: 'next' | 'previous' | '') => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Resets the carousel
     */
    shouldUpdateCarousel: boolean;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets the resetting of the carousel
     */
    setShouldUpdateCarousel: (state: boolean) => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Current set of segments
     */
    currentPage: number;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets the current set of segments
     */
    setCurrentPage: (state: number) => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Checks if first set of segments
     */
    isFirstPage: boolean;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets if first set of segments
     */
    setIsFirstPage: (state: boolean) => void;
    /**
     * Prop of useSegmentedCarouselController.
     * Checks if last set of segments
     */
    isLastPage: boolean;
    /**
     * Prop of useSegmentedCarouselController.
     * Sets if last set of segments
     */
    setIsLastPage: (state: boolean) => void;
}

interface SegmentedCarouselComponent extends React.FC<SegmentedCarouselProps> {
    Header: React.FC<SegmentedCarouselHeaderProps>;
    Footer: React.FC<SegmentedCarouselFooterProps>;
    Track: React.FC<SegmentedCarouselTrackProps>;
    ToggleChips: React.FC<SegmentedCarouselChipsProps>;
}

/**
 * Segmented carousel component.
 * Intended to be used with the useSegmentedCarouselController hook
 */
export const SegmentedCarousel: SegmentedCarouselComponent = ({
    children,
    syncedPadding,
    rootPaddingTop,
    rootPaddingRight,
    rootPaddingBottom,
    rootPaddingLeft,
    shouldPaginate,
    controlPosition,
    hideOverflow,
    headerGap,
    footerGap,
    visibleSegments,

    // useSegmentedCarouselController props:
    slideTo,
    setSlideTo,
    shouldUpdateCarousel,
    setShouldUpdateCarousel,
    currentPage,
    setCurrentPage,
    isFirstPage,
    setIsFirstPage,
    isLastPage,
    setIsLastPage,
}: SegmentedCarouselProps): JSX.Element => {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [defaults, setDefaults] = useState<DefaultsProps>(initDefaults);
    const rootClasses = classNames(styles.root, 'segmented-carousel__root');
    const wrapperClasses = classNames(
        styles.wrapper,
        'segmented-carousel__wrapper',
    );
    const rootStyles = {
        paddingTop: rootPaddingTop ?? defaults.rootPaddingTop,
        paddingBottom: rootPaddingBottom ?? defaults.rootPaddingBottom,
        paddingLeft: rootPaddingLeft ?? defaults.rootPaddingLeft,
        paddingRight: rootPaddingRight ?? defaults.rootPaddingRight,
    };

    // set in-element breakpoint with the root element width
    useEffect(() => {
        if (rootRef.current) {
            const rootWidth = rootRef.current.clientWidth;
            setDefaults(getRootBreakpointDefaults(rootWidth));
        }
    }, [rootRef, shouldUpdateCarousel]);

    // set context values
    const segmentedCarouselContextProviderValue = useMemo(() => {
        const getInitControlPosition = () => {
            if (
                controlPosition === CONTROL_POSITIONS.TOP ||
                controlPosition === CONTROL_POSITIONS.BOTTOM ||
                controlPosition === CONTROL_POSITIONS.HIDDEN
            ) {
                return controlPosition;
            }

            if (
                defaults.size === BREAKPOINT_SIZES.SM ||
                defaults.size === BREAKPOINT_SIZES.MD
            ) {
                return CONTROL_POSITIONS.BOTTOM;
            }

            return defaults.controlPosition;
        };

        return {
            defaults,
            rootWidth: rootRef.current?.clientWidth ?? 0,
            syncedPadding: syncedPadding ?? defaults.syncedPadding,
            shouldPaginate: shouldPaginate ?? defaults.shouldPaginate,
            controlPosition: getInitControlPosition(),
            headerGap: headerGap ?? defaults.headerGap,
            footerGap: footerGap ?? defaults.footerGap,
            visibleSegments: visibleSegments || defaults.visibleSegments,
            hideOverflow: hideOverflow ?? defaults.hideOverflow,
            /**
             * When hideOverflow is false, rootPaddingLeft will be used for inner
             * children margins to create the illusion of the root having padding,
             * yet allow for overflow content
             */
            rootPaddingLeft: rootPaddingLeft ?? defaults.rootPaddingLeft,
            rootPaddingRight: rootPaddingRight ?? defaults.rootPaddingRight,
            slideTo,
            setSlideTo,
            shouldUpdateCarousel,
            setShouldUpdateCarousel,
            currentPage,
            setCurrentPage,
            isFirstPage,
            setIsFirstPage,
            isLastPage,
            setIsLastPage,
        };
    }, [
        defaults,
        rootRef,
        syncedPadding,
        shouldPaginate,
        controlPosition,
        headerGap,
        footerGap,
        visibleSegments,
        slideTo,
        hideOverflow,
        rootPaddingLeft,
        rootPaddingRight,
        setSlideTo,
        shouldUpdateCarousel,
        setShouldUpdateCarousel,
        currentPage,
        setCurrentPage,
        isFirstPage,
        setIsFirstPage,
        isLastPage,
        setIsLastPage,
    ]);

    return (
        <div
            ref={rootRef}
            className={rootClasses}
            style={{
                paddingTop: rootStyles.paddingTop,
                paddingBottom: rootStyles.paddingBottom,
            }}
        >
            <div
                className={wrapperClasses}
                style={{
                    clipPath: hideOverflow
                        ? `inset(0 ${rootStyles.paddingRight}px 0 ${rootStyles.paddingLeft}px)`
                        : undefined,
                }}
            >
                <SegmentedCarouselContext.Provider
                    value={segmentedCarouselContextProviderValue}
                >
                    {children}
                </SegmentedCarouselContext.Provider>
            </div>
        </div>
    );
};

/**
 * Attach components as properties of the main SegmentedCarousel component
 */
SegmentedCarousel.Header = SegmentedCarouselHeader;
SegmentedCarousel.Footer = SegmentedCarouselFooter;
SegmentedCarousel.Track = SegmentedCarouselTrack;
SegmentedCarousel.ToggleChips = SegmentedCarouselChips;
