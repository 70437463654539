import {
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    OTHER_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    RELIGION_EVENTBRITE_CATEGORY,
    SCHOOL_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { gettext } from '@eventbrite/i18n';
import type { Tab } from './types';

export const categoryRadiusMap: Record<string, { id: string; radius: number }> =
    {
        'Auto, Boat & Air': {
            id: AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
            radius: 50,
        },
        Business: {
            id: BUSINESS_EVENTBRITE_CATEGORY,
            radius: 5,
        },
        'Charity & Causes': {
            id: CHARITY_EVENTBRITE_CATEGORY,
            radius: 5,
        },
        Community: {
            id: COMMUNITY_EVENTBRITE_CATEGORY,
            radius: 4,
        },
        'Family & Education': {
            id: FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
            radius: 5,
        },
        Fashion: {
            id: FASHION_EVENTBRITE_CATEGORY,
            radius: 7,
        },
        'Film & Media': {
            id: FILM_EVENTBRITE_CATEGORY,
            radius: 4,
        },
        'Food & Drink': {
            id: FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
            radius: 5,
        },
        Government: {
            id: POLITICS_EVENTBRITE_CATEGORY,
            radius: 15,
        },
        Health: {
            id: HEALTH_EVENTBRITE_CATEGORY,
            radius: 5,
        },
        Hobbies: {
            id: HOBBIES_EVENTBRITE_CATEGORY,
            radius: 15,
        },
        'Home & Lifestyle': {
            id: HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
            radius: 6,
        },
        Music: {
            id: MUSIC_EVENTBRITE_CATEGORY,
            radius: 6,
        },
        Other: {
            id: OTHER_EVENTBRITE_CATEGORY,
            radius: 7,
        },
        Arts: {
            id: PERFORMING_EVENTBRITE_CATEGORY,
            radius: 5,
        },
        Spirituality: {
            id: RELIGION_EVENTBRITE_CATEGORY,
            radius: 10,
        },
        'School Activities': {
            id: SCHOOL_EVENTBRITE_CATEGORY,
            radius: 9,
        },
        'Science & Tech': {
            id: SCIENCE_EVENTBRITE_CATEGORY,
            radius: 6,
        },
        Holiday: {
            id: SEASONAL_EVENTBRITE_CATEGORY,
            radius: 6,
        },
        'Sports & Fitness': {
            id: SPORTS_EVENTBRITE_CATEGORY,
            radius: 6,
        },
        'Travel & Outdoor': {
            id: TRAVEL_EVENTBRITE_CATEGORY,
            radius: 22,
        },
    };

export const getTabs = (currencySymbol: string, category: string): Tab[] => {
    const categoryRadius = categoryRadiusMap[category]?.radius || 5;
    return [
        {
            id: 'under30',
            label: gettext('Under %(currencySymbol)s30', {
                currencySymbol,
            }),
            minPrice: 0,
            maxPrice: 2900,
        },
        {
            id: 'pointRadius',
            label: gettext('Nearby'),
            point_radius: categoryRadius,
        },
    ];
};
