import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { useIsFirstHierarchyLevel } from '../../../experimentation';
import './Summary.scss';

type SummaryProps = {
    summary: string;
};

export const Summary = ({ summary }: SummaryProps) => {
    const isFirstHierarchyLevel = useIsFirstHierarchyLevel();
    return (
        <>
            {summary &&
                (isFirstHierarchyLevel ? (
                    <Typography
                        as="p"
                        color="neutral-1000"
                        variant="body-md-bold"
                    >
                        {summary}
                    </Typography>
                ) : (
                    <p className="summary">
                        {/* Summary comes from the summary field in the creation flow */}
                        <strong>{summary}</strong>
                    </p>
                ))}
        </>
    );
};
