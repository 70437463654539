import { getExperiment, useExperiment } from '@eventbrite/statsig';
import { useEffect, useState } from 'react';
import { CheckoutDisqualifications, CheckoutType } from '../types';
import {
    DETACH_INTERESTS_FROM_USER_MENU,
    LISTING_FIRST_HIERARCHY_LEVEL_EXPERIMENT,
    LISTING_REDUCE_LOGIN_FRICTION_EXPERIMENT,
    LISTING_SECOND_HIERARCHY_LEVEL_EXPERIMENT,
    LISTING_TEST_AA_SSR_EXPERIMENT,
    LISTING_THIRD_HIERARCHY_LEVEL_EXPERIMENT,
    LISTING_VIDEO_AUTOPLAY_EXPERIMENT,
    NIGHTLIFE_CAROUSEL_EXPERIMENT,
    NIGHTLIFE_EXPERIMENT_VALUES,
    ONE_TICKET_TYPE_EXPERIMENT,
    PRICE_AND_DISTANCE_EXPERIMENT,
    PRICE_AND_DISTANCE_EXPERIMENT_VALUES,
} from './constants';

type ExperimentDef = {
    experimentName: string;
    param: string;
    defaultValue: string | boolean;
};

export const genericGetRawVariant = <T>(experiment: ExperimentDef): T =>
    getExperiment(
        experiment.experimentName,
        experiment.param,
        experiment.defaultValue,
    );

export const genericGetBooleanExperiment = (
    experiment: ExperimentDef,
): boolean => {
    const value = genericGetRawVariant<string | boolean>(experiment);
    return value === true || value === 'true';
};

export const isReduceLoginFrictionExperiment = () =>
    genericGetBooleanExperiment(LISTING_REDUCE_LOGIN_FRICTION_EXPERIMENT);

export const useIsFirstHierarchyLevel = () => {
    const { value } = useExperiment<boolean | null>(
        LISTING_FIRST_HIERARCHY_LEVEL_EXPERIMENT.experimentName,
        LISTING_FIRST_HIERARCHY_LEVEL_EXPERIMENT.param,
        LISTING_FIRST_HIERARCHY_LEVEL_EXPERIMENT.defaultValue,
    );

    // Value could is a boolean if coming from Statsig or a string if coming from override.
    return value === true || (value as unknown as string) === 'true';
};

export const isSecondHierarchyLevel = () =>
    genericGetRawVariant<boolean>(LISTING_SECOND_HIERARCHY_LEVEL_EXPERIMENT);

export const isThirdHierarchyLevel = () =>
    genericGetRawVariant<boolean>(LISTING_THIRD_HIERARCHY_LEVEL_EXPERIMENT);

export const isAutoplayVideoExperimentVariant = () =>
    genericGetRawVariant(LISTING_VIDEO_AUTOPLAY_EXPERIMENT);

export const useTestAAExperiment = () => {
    const { value } = useExperiment<boolean | null>(
        LISTING_TEST_AA_SSR_EXPERIMENT.experimentName,
        LISTING_TEST_AA_SSR_EXPERIMENT.param,
        LISTING_TEST_AA_SSR_EXPERIMENT.defaultValue,
    );

    return value === true || (value as unknown as string) === 'true';
};

export const isDetachInterestsExperimentEnabled = () =>
    genericGetBooleanExperiment(DETACH_INTERESTS_FROM_USER_MENU);

export const getCheckoutType = (
    isMultiTicketEnabled: boolean,
    compactCheckoutDisqualifications: CheckoutDisqualifications,
) => {
    if (!isMultiTicketEnabled) return CheckoutType.Compact;
    if (compactCheckoutDisqualifications.more_than_one_ticket)
        return CheckoutType.OnlyCheckout;
    return genericGetRawVariant<CheckoutType>(ONE_TICKET_TYPE_EXPERIMENT);
};

export const NightlifeCarouselExperiment = () => {
    const [showNightlifeCarousel, setShowNightlifeCarousel] = useState(false);

    useEffect(() => {
        const variant = getExperiment(
            NIGHTLIFE_CAROUSEL_EXPERIMENT.experimentName,
            NIGHTLIFE_CAROUSEL_EXPERIMENT.param,
            NIGHTLIFE_CAROUSEL_EXPERIMENT.defaultValue,
        );

        setShowNightlifeCarousel(variant == NIGHTLIFE_EXPERIMENT_VALUES.TEST);
    }, []);
    return showNightlifeCarousel;
};

export const usePriceAndDistanceCarouselExperiment = () => {
    const [showCarousel, setShowCarousel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchExperimentValue = async () => {
            setIsLoading(true);
            const variant = await getExperiment(
                PRICE_AND_DISTANCE_EXPERIMENT.experimentName,
                PRICE_AND_DISTANCE_EXPERIMENT.param,
                PRICE_AND_DISTANCE_EXPERIMENT.defaultValue,
            );
            setShowCarousel(
                variant === PRICE_AND_DISTANCE_EXPERIMENT_VALUES.TEST,
            );
            setIsLoading(false);
        };
        fetchExperimentValue();
    }, []);
    return { showCarousel, isLoading };
};
