import { useCallback, useEffect, type FC } from 'react';

import { track } from '@eventbrite/datalayer-library';
import { getExperiment } from '@eventbrite/statsig';

import { useSurfacingExperimentContext } from '../../contexts';

import { SURFACING_RATING_EXPERIMENT } from '../../experimentation/constants';

import { getRatingsData } from '../RelatedEvents/api';
import { isRatingsCriteriaMatching } from '../_shared/OrganizerInfo/helperFunctions';

import type { TSurfacingExperimentVariant } from '../../contexts';
import type { IRatingResponse } from '../RelatedEvents/types/api';

interface ISurfacingExperimentDataHandlerProps {
    organizerId: string;
}

const getRatings = (ratingsData: IRatingResponse['ratings']) => ({
    starRating: Number(ratingsData.overall_experience.average),
    totalRating: Number(ratingsData.overall_experience.total),
});

const trackEligibleUsersForControlVariant = (
    variant: TSurfacingExperimentVariant,
    organizerId: string,
    ratingsData: IRatingResponse['ratings'],
) => {
    if (variant === 'control') {
        const { starRating, totalRating } = getRatings(ratingsData);
        track({
            eventName:
                SURFACING_RATING_EXPERIMENT.eligibleOrganizerControlVariant,
            eventData: {
                organizerId,
                rating: starRating,
                totalRatings: totalRating,
            },
        });
    }
};

export const SurfacingExperimentDataHandler: FC<
    ISurfacingExperimentDataHandlerProps
> = ({ organizerId }) => {
    const {
        ratingsData,
        setVariant,
        setRatingsData,
        setShowOrganiserInfoLoader,
    } = useSurfacingExperimentContext();

    const setStatsigVariantSafe = useCallback(
        (ratingsData: IRatingResponse['ratings']) => {
            if (isRatingsCriteriaMatching(ratingsData)) {
                const variant = getExperiment<
                    string,
                    TSurfacingExperimentVariant
                >(
                    SURFACING_RATING_EXPERIMENT.experimentName,
                    SURFACING_RATING_EXPERIMENT.param,
                    SURFACING_RATING_EXPERIMENT.defaultValue,
                );
                setVariant(variant);
                trackEligibleUsersForControlVariant(
                    variant,
                    organizerId,
                    ratingsData,
                );
            } else {
                setVariant('control');
            }
            setShowOrganiserInfoLoader(false);
        },
        [organizerId, setShowOrganiserInfoLoader, setVariant],
    );

    useEffect(() => {
        const fetchRatingsData = async () => {
            try {
                const ratingsData = await getRatingsData(organizerId);
                setRatingsData(ratingsData);
            } catch (e) {
                console.error('cannot fetch ratings data', e);
                setVariant('control');
                setShowOrganiserInfoLoader(false);
            }
        };
        fetchRatingsData();
    }, [organizerId, setRatingsData, setShowOrganiserInfoLoader, setVariant]);

    useEffect(() => {
        if (!ratingsData) return;
        setStatsigVariantSafe(ratingsData);
    }, [ratingsData, setStatsigVariantSafe]);

    return null;
};
