import {
    constants as dateTimeConstants,
    getDateFnsLocale,
} from '@eventbrite/datetime-fns';
import { getAttendeeTimezone } from '@eventbrite/event-datetime';
import { format } from 'date-fns-tz';
import { LocalizedDateTime } from '../../../types';

type GetFormattedStartDateParams = {
    eventStartDateTime: LocalizedDateTime;
    isOnlineEvent: boolean;
    locale: string;
    isFirstHierarchyLevelActive: boolean;
};

export const getFormattedStartDate = ({
    eventStartDateTime,
    isOnlineEvent,
    locale,
    isFirstHierarchyLevelActive,
}: GetFormattedStartDateParams) => {
    const date = isOnlineEvent
        ? eventStartDateTime.utc
        : eventStartDateTime.local;
    const dateObj = new Date(date);
    const timeZone = isOnlineEvent
        ? getAttendeeTimezone() ?? eventStartDateTime.timezone
        : eventStartDateTime.timezone;

    const { DATE_FORMATS, DEFAULT_TIME_FORMAT } = dateTimeConstants;

    const shortDate = format(dateObj, DATE_FORMATS[locale] || 'EEEE, MMMM d', {
        timeZone,
        locale: getDateFnsLocale(locale),
    });
    let formattedDateTime = shortDate;

    if (isFirstHierarchyLevelActive) {
        const shortTime = format(dateObj, `${DEFAULT_TIME_FORMAT}`, {
            timeZone,
            locale: getDateFnsLocale(locale),
        });

        formattedDateTime += ` · ${shortTime}`;
    }

    const dateTime = format(dateObj, 'yyy-MM-dd', {
        timeZone,
    });

    return { formattedDateTime, dateTime };
};
