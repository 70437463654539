import { HAS_DOCUMENT } from '@eventbrite/eds-utils';
import { getLocationData } from '@eventbrite/redux-destination';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import { useEffect, useState } from 'react';
import { getPlaceFromRequest } from '../api';
import type { LocationData } from '../types';

const useLocationData = () => {
    const [location, setLocation] = useState<LocationData>({});

    useEffect(() => {
        const fetchLocation = async () => {
            if (!HAS_DOCUMENT) return;

            try {
                const { place } = await getPlaceFromRequest();
                setLocation({
                    ...deepKeysToCamel(place),
                    isOnline: false,
                });
            } catch (error) {
                const locationData = getLocationData();
                if (locationData) {
                    setLocation(deepKeysToCamel(locationData));
                } else {
                    setLocation({});
                }
            }
        };

        fetchLocation();
    }, []);

    return location;
};

export default useLocationData;
