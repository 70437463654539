import {
    ChildEventProps,
    SALES_STATUS,
    UNDETERMINED_SALES_STATUSES,
} from '../../../../types';
import {
    eventIsOnSale,
    eventIsSalesEnded,
    eventIsSoldOut,
} from '../../../../utils';

export const isChildAvailable =
    ({
        isSoldOut,
        isTimedEntryEnabled,
    }: {
        isSoldOut?: boolean;
        isTimedEntryEnabled?: boolean;
    }) =>
    (event: ChildEventProps) => {
        if (
            eventIsOnSale(event) ||
            UNDETERMINED_SALES_STATUSES.includes(
                event?.sales_status as SALES_STATUS,
            )
        ) {
            return true;
        }

        return (
            (isTimedEntryEnabled && !isSoldOut && eventIsSoldOut(event)) ||
            eventIsSalesEnded(event)
        );
    };

export const shouldShowMoreOptions = (
    childEventsOnSlider: ChildEventProps[],
    isTimedEntryEnabled?: boolean,
    isSoldOut?: boolean,
) => {
    const slicedEventDates = isTimedEntryEnabled ? 4 : 5;
    const availableFollowingEvents = childEventsOnSlider
        .slice(slicedEventDates)
        .filter(isChildAvailable({ isSoldOut, isTimedEntryEnabled }));
    return (
        childEventsOnSlider.length > slicedEventDates &&
        availableFollowingEvents.length > 0
    );
};

export const getMonthNameAndYear = (dateString: string) => {
    const date = new Date(dateString);
    const month: number = date.getMonth() + 1;
    const monthString = month < 10 ? String(`0${month}`) : String(month);
    const year = date.getFullYear();
    return { year, monthString };
};

export const convertToISOString = (dateString: Date) => {
    const date = new Date(dateString);
    const isoString = date.toISOString().split('.')[0] + 'Z';
    return isoString;
};
