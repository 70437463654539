import { sdkRequest } from '@eventbrite/http';
import type {
    OrganicEventSearchApiPayload,
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
} from '@eventbrite/search-utils';

const EXPANSIONS: ExpansionBody = {
    'expand.destination_event': [
        'primary_venue',
        'image',
        'ticket_availability',
        'saves',
        'event_sales_status',
        'primary_organizer',
        'public_collections',
    ],
};

type ExpansionBody = {
    'expand.destination_event'?: string[];
    'expand.destination_profile'?: string[];
};

export const search = (
    eventSearch: OrganicEventSearchFiltersApiPayload,
    expansions = EXPANSIONS,
): Promise<OrganicEventSearchApiResponse> => {
    const filters: OrganicEventSearchApiPayload = {
        browse_surface: 'listings',
        event_search: eventSearch,
        ...expansions,
    };
    return searchOrganicEvents(filters);
};

export function searchOrganicEvents(
    search: OrganicEventSearchApiPayload,
): Promise<OrganicEventSearchApiResponse> {
    return sdkRequest('/api/v3/destination/search/', {
        method: 'POST',
        body: JSON.stringify(search),
    });
}
