import React, { createContext, useEffect, useState } from 'react';
import styles from './Layout.module.scss';

export enum Layouts {
    exploreMoreEvents = 'exploreMoreEvents',
    hierarchy = 'hierarchy',
}

export type LayoutType = {
    exploreMoreEvents: boolean;
    hierarchyExperiment?: boolean;
};

export const LayoutContext = createContext<LayoutType | null>(null);

export const Layout: React.FC<LayoutType> = ({
    children,
    exploreMoreEvents,
    hierarchyExperiment,
}) => {
    const [layoutsToApply, setLayoutsToApply] = useState('');

    useEffect(() => {
        const layouts = [styles.layout];

        if (exploreMoreEvents) {
            layouts.push(styles.exploreMoreEvents);
        }

        if (hierarchyExperiment) {
            layouts.push(styles.hierarchyExperiment);
        }

        setLayoutsToApply(layouts.join(' '));
    }, [exploreMoreEvents, hierarchyExperiment]);

    return (
        <LayoutContext.Provider
            value={{
                exploreMoreEvents: exploreMoreEvents,
                hierarchyExperiment: hierarchyExperiment,
            }}
        >
            <div className={layoutsToApply}>{children}</div>
        </LayoutContext.Provider>
    );
};
