import {
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import {
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
} from '@eventbrite/search-utils';
import { useQuery } from '@tanstack/react-query';
import { search } from './api';

interface SearchResponseData {
    events: FormattedEvent[];
    eventSearch: OrganicEventSearchFiltersApiPayload;
}

export function getBudgetAndNearbyEventsQueryKey(queryType: string) {
    return ['budget-and-nearby-events', 'related-events', queryType];
}

export type UseBudgetAndNearbyEventsProps = {
    queryOverload: OrganicEventSearchFiltersApiPayload;
    queryType: string;
};
export function useBudgetAndNearbyEvents({
    queryOverload,
    queryType,
}: UseBudgetAndNearbyEventsProps) {
    const queryKey = getBudgetAndNearbyEventsQueryKey(queryType);
    const response = useQuery({
        queryKey,
        queryFn: async () => {
            const data = await search(queryOverload);
            return transformResponse(data);
        },
        staleTime: 300000, //5 minutes: 5 * 60 * 1000
    });
    return response;
}

const transformResponse = (
    data: OrganicEventSearchApiResponse,
): SearchResponseData => {
    return {
        events: [
            ...data.events.promoted_results.map(transformDestinationEvent),
            ...data.events.results.map(transformDestinationEvent),
        ],
        eventSearch: data.event_search as any,
    };
};
