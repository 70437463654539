import React from 'react';

import { gettext } from '@eventbrite/i18n';
import { calculateTopOffset } from '../helperFunctions';

import { useFollowCount } from '../../FollowButton/FollowButton';

import { EventDetailsSectionTitle } from '../../../EventDetails/EventDetails';
import { ListingSignals } from '../ListingSignals';
import { OrganizerAvatar } from './OrganizerAvatar';
import { OrganizerDescription } from './OrganizerDescription';
import { OrganizerInfoActions } from './OrganizerInfoActions';
import { OrganizerNameLink } from './OrganizerLink';
import { OrganizerSocialHandles } from './OrganizerSocialHandles';

import './DescriptiveOrganizerPanelViewVariant.scss';

import type { OrganizerPanelLayoutProps } from '../../../OrganizerPanel/OrganizerPanelLayout';
import type { DescriptiveOrganizerInfoProps } from '../DescriptiveOrganizerInfo';

export type TDescriptiveOrganizerPanelViewVariantProps =
    OrganizerPanelLayoutProps & DescriptiveOrganizerInfoProps;

export const DescriptiveOrganizerPanelViewVariant = ({
    captchaKey,
    eventName,
    gaCategory,
    isAuthenticated,
    organizerAvatar,
    organizerDescription,
    organizerFacebook,
    organizerId,
    organizerName,
    organizerTwitter,
    organizerUrl,
    organizerWebsite,
    pageArea,
    eventId,
}: TDescriptiveOrganizerPanelViewVariantProps) => {
    const followersCount = useFollowCount();
    const isMobile = calculateTopOffset();

    return (
        <section aria-label="Organizer profile">
            <EventDetailsSectionTitle>
                <h2>
                    {isMobile
                        ? gettext('About the organizer')
                        : gettext('Organized by')}
                </h2>
            </EventDetailsSectionTitle>
            <div className="descriptive-organizer-info-mobile variant">
                <OrganizerAvatar organizerAvatar={organizerAvatar} />
                <div className="descriptive-organizer-info-heading-signal-container variant">
                    <OrganizerNameLink
                        organizerName={organizerName}
                        organizerUrl={organizerUrl}
                    />
                    <ListingSignals followCount={followersCount ?? 0} />
                </div>
                <OrganizerInfoActions
                    organizerId={organizerId}
                    organizerName={organizerName}
                    captchaKey={captchaKey}
                    isAuthenticated={isAuthenticated}
                    eventName={eventName}
                    eventId={eventId}
                    gaCategory={gaCategory}
                    pageArea={pageArea}
                />
                <OrganizerDescription
                    description={organizerDescription ?? ''}
                    previewLineCount={{
                        mobile: 5,
                        tablet: 5,
                        desktop: 3,
                    }}
                    buttonLabels={{
                        show: gettext('Show more'),
                        hide: gettext('Show less'),
                    }}
                />
                <OrganizerSocialHandles
                    facebook={organizerFacebook}
                    twitter={organizerTwitter}
                    website={organizerWebsite}
                />
            </div>
        </section>
    );
};
