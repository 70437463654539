import { gettext } from '@eventbrite/i18n';
import { CalendarCheckmarkIcon, Typography } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import {
    SALES_STATUS,
    UNDETERMINED_SALES_STATUSES,
    type ChildEventProps,
    type DateAndTimeScopedProps,
    type Event,
    type LocalizedDateTime,
} from '../../../../types';
import {
    Detail,
    DetailContent,
    DetailInner,
} from '../../../_shared/Detail/Detail';

import {
    getAttendeeTimezone,
    shouldShowDateInAttendeeTimezone,
} from '@eventbrite/event-datetime';
import loadable from '@loadable/component';
import {
    eventIsOnSale,
    eventIsSalesEnded,
    eventIsSoldOut,
} from '../../../../utils';
import {
    EventDetailsSection,
    EventDetailsSectionTitle,
} from '../../EventDetails';
import { ChildEventsSlider } from '../ChildEventsSelection/ChildEventsSlider';
import { shouldShowMoreOptions } from './utils';

import { format, utcToZonedTime } from 'date-fns-tz';
import { useEventBasicInformationContext } from '../../../../contexts';
import { MoreOptionsButton } from '../ChildEventsSelection/MoreOptionsButton';
import './DateAndTime.scss';
import { DisplayDate } from './DisplayDate';
import { useTimedEntryCalendarData } from './useTimedEntryCalendarData';

export type DateAndTimeProps = Pick<
    DateAndTimeScopedProps,
    'doorTime' | 'shouldShowDoorTime' | 'displayDate'
> & {
    locale: string;
    isTimedEntryEnabled: boolean;
    event: Pick<
        Event,
        | 'start'
        | 'end'
        | 'hideStartDate'
        | 'hideEndDate'
        | 'childEvents'
        | 'isOnlineEvent'
    > & {
        listingDisplayFlags: {
            shouldShowTimezone: boolean;
        };
    };
};

const getDateInAttendeeTimezone = (
    date: LocalizedDateTime,
    userTimezone: string | null,
) => {
    const formattedDate = format(
        utcToZonedTime(new Date(date.utc), userTimezone ?? ''),
        'yyyy-MM-dd HH:mm:ss',
        {
            timeZone: userTimezone ?? undefined,
        },
    );

    return {
        ...date,
        local: formattedDate,
        timezone: userTimezone as string,
    };
};

const getChildEventsInAttendeeTimeZone = (
    childEvents: ChildEventProps[],
    userTimezone: string | null,
): Array<ChildEventProps> => {
    const childEventsInAttendeeTimeZone: Array<ChildEventProps> = [];

    childEvents?.forEach((childEvent) => {
        const childEventInAttendeeTimeZone: ChildEventProps = {
            ...childEvent,
            start: getDateInAttendeeTimezone(childEvent.start, userTimezone),
            end: getDateInAttendeeTimezone(childEvent.end, userTimezone),
        };
        childEventsInAttendeeTimeZone.push(childEventInAttendeeTimeZone);
    });

    return childEventsInAttendeeTimeZone;
};

const LABEL_ID = 'date-time-heading';

const filterOnSaleEventDates = (childEvents: Array<ChildEventProps>) => {
    return childEvents.filter(
        (event) =>
            eventIsOnSale(event) ||
            UNDETERMINED_SALES_STATUSES.includes(
                event?.sales_status as SALES_STATUS,
            ),
    );
};

const filterSoldOutEventDates = (childEvents: Array<ChildEventProps>) => {
    return childEvents.filter((event) => {
        if (!event.availability) {
            return false;
        }
        return (
            event.availability.toLowerCase() === 'soldout' ||
            event.availability.toLowerCase() === 'salesended'
        );
    });
};

export const DateAndTime = ({
    event,
    displayDate,
    shouldShowDoorTime,
    doorTime,
    locale,
    isTimedEntryEnabled,
}: DateAndTimeProps) => {
    const [moreOptionsModalOpened, setMoreOptionsModalOpened] = useState(false);
    const basicEventInformation = useEventBasicInformationContext();
    const { fetchTimedEntryCalendarData, loading } =
        useTimedEntryCalendarData();

    const LazyMoreOptionsModal = useMemo(
        () =>
            loadable(
                () =>
                    import(
                        '../ChildEventsSelection/MoreOptionsModal/MoreOptionsModal'
                    ),
            ),
        [],
    );
    const isSoldOut = basicEventInformation.isSoldOut;

    const { childEvents, isOnlineEvent } = event;
    const childEventsToShow = isTimedEntryEnabled
        ? basicEventInformation.currentEventSessionsTimedEntry
        : childEvents;
    let eventsToDisplayOnSlider: ChildEventProps[] = childEventsToShow
        ? childEventsToShow.filter(
              (event: ChildEventProps) =>
                  eventIsSoldOut(event) ||
                  eventIsOnSale(event) ||
                  (isTimedEntryEnabled && eventIsSalesEnded(event)) ||
                  UNDETERMINED_SALES_STATUSES.includes(
                      event?.sales_status as SALES_STATUS,
                  ),
          )
        : [];

    // Store the initial events on the first render which decides whether to show more option card or not
    const initialEventsForMoreOptions = useMemo(
        () => eventsToDisplayOnSlider,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (!displayDate) {
        return null;
    }

    const selectedEventData = {
        ...event,
        id: basicEventInformation.id,
        start: basicEventInformation?.start || event?.start,
        end: basicEventInformation.end || event.end,
    };

    if (isOnlineEvent) {
        const userTimezone = getAttendeeTimezone();
        const shouldShowChildEventsInAttendeeTimeZone =
            shouldShowDateInAttendeeTimezone({ isOnlineEvent, userTimezone });
        if (shouldShowChildEventsInAttendeeTimeZone) {
            eventsToDisplayOnSlider = getChildEventsInAttendeeTimeZone(
                eventsToDisplayOnSlider,
                userTimezone,
            );

            selectedEventData.start = getDateInAttendeeTimezone(
                selectedEventData.start,
                userTimezone,
            );
            selectedEventData.end = getDateInAttendeeTimezone(
                selectedEventData.end,
                userTimezone,
            );
        }
    }

    const handleMoreOptionsClick = async () => {
        setMoreOptionsModalOpened(true);
        const startDateTime =
            selectedEventData?.start.local.split('T')[0] ??
            childEventsToShow?.[0]?.start?.local.split('T')[0];
        if (isTimedEntryEnabled) {
            await fetchTimedEntryCalendarData(startDateTime ?? '');
        }
    };

    const shouldShowChildEvents = eventsToDisplayOnSlider.length > 0;
    const onSaleEvents = isTimedEntryEnabled
        ? filterOnSaleEventDates(basicEventInformation.childEvents ?? [])
        : filterOnSaleEventDates(childEvents ?? []);

    const soldOutEvents = isTimedEntryEnabled
        ? filterSoldOutEventDates(basicEventInformation.childEvents ?? [])
        : [];

    const AvailabilityAndDateInfo = () => {
        if (
            isSoldOut &&
            isTimedEntryEnabled &&
            basicEventInformation.isRepeating
        ) {
            return (
                <div className="not-available-text">
                    <Typography color="neutral-800" variant="body-md">
                        {gettext('No available dates')}
                    </Typography>
                </div>
            );
        }
        return (
            <div className="date-info">
                <CalendarCheckmarkIcon className="date-info__icon" />
                <div data-testid="display-date-container">
                    <DisplayDate
                        doorTime={shouldShowDoorTime ? doorTime : null}
                        event={{
                            ...selectedEventData,
                            isOnlineEvent,
                        }}
                        locale={locale}
                    />
                    {eventsToDisplayOnSlider &&
                        shouldShowMoreOptions(
                            eventsToDisplayOnSlider,
                            isTimedEntryEnabled,
                            isSoldOut,
                        ) && (
                            <MoreOptionsButton
                                onButtonClick={handleMoreOptionsClick}
                            />
                        )}
                </div>
            </div>
        );
    };

    return (
        <>
            <EventDetailsSection aria-labelledby={LABEL_ID}>
                <Detail>
                    <DetailInner>
                        <DetailContent>
                            <EventDetailsSectionTitle>
                                {shouldShowChildEvents ? (
                                    <h2>{gettext('Select date and time')}</h2>
                                ) : (
                                    <h2>{gettext('Date and time')}</h2>
                                )}
                            </EventDetailsSectionTitle>
                            <div
                                className={classNames({
                                    'select-date-and-time':
                                        shouldShowChildEvents,
                                })}
                            >
                                <AvailabilityAndDateInfo />
                                {shouldShowChildEvents && (
                                    <ChildEventsSlider
                                        childEvents={eventsToDisplayOnSlider}
                                        shouldShowMoreOptions={
                                            eventsToDisplayOnSlider &&
                                            shouldShowMoreOptions(
                                                initialEventsForMoreOptions,
                                                isTimedEntryEnabled,
                                            )
                                        }
                                        currentEventId={selectedEventData.id}
                                        onMoreOptionsClick={
                                            handleMoreOptionsClick
                                        }
                                        isTimedEntryEnabled={
                                            isTimedEntryEnabled
                                        }
                                    />
                                )}
                            </div>
                        </DetailContent>
                    </DetailInner>
                </Detail>
                {moreOptionsModalOpened && (
                    <LazyMoreOptionsModal
                        onClose={() => setMoreOptionsModalOpened(false)}
                        opened={moreOptionsModalOpened}
                        childEvents={onSaleEvents}
                        currentEventStartDate={selectedEventData.start}
                        currentEventId={selectedEventData.id}
                        isTimedEntryEnabled={isTimedEntryEnabled}
                        soldOutEvents={soldOutEvents}
                        isLoading={loading}
                    />
                )}
            </EventDetailsSection>
        </>
    );
};
