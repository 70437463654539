import classNames from 'classnames';
import React from 'react';

import { gettext } from '@eventbrite/i18n';
import { numberFormatter } from '../../../utils/utils';

import { FOLLOWERS_THRESHOLD } from '../../constants';
import { RATING_INFO_TEST_ID } from './test-id.constant';

import styles from './Followers.module.scss';
import ratingInfoStyles from './RatingInfo.module.scss';

interface IFollowersProps {
    followersCount: number;
}

export const Followers: React.FC<IFollowersProps> = ({ followersCount }) => {
    const _showFollowersCount = followersCount > FOLLOWERS_THRESHOLD;
    if (!_showFollowersCount) return null;

    return (
        <p
            className={classNames(
                styles.followersCountText,
                ratingInfoStyles.withTopMargin,
            )}
            data-testid={RATING_INFO_TEST_ID.FOLLOWERS_CONTAINER}
        >
            {numberFormatter(followersCount)} {gettext('followers')}
        </p>
    );
};
