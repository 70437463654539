import { FormattedEvent } from '@eventbrite/event-renderer';

export interface EventCardsMapProps {
    events: FormattedEvent[];
    isAuthenticated: boolean;
    locale: string;
    bucketLabel: string;
}

export const AFF_NIGHTLIFE_EVENTS = 'ebdsrelistingnightlife';
