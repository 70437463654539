import React from 'react';

import { getShortenedNumber } from '@eventbrite/eds-utils';
import { gettext } from '@eventbrite/i18n';
import { useSurfacingExperimentContext } from '../../../../contexts';
import { numberFormatter } from '../../../utils/utils';

import { FOLLOWERS_THRESHOLD, TOTAL_RATING_THRESHOLD } from '../../constants';

import StarIcon from '../RatingInfo/StarIcon';

import styles from './ListingSignals.module.scss';

interface IListingSignalsProps {
    followCount: number;
}

export const ListingSignals: React.FC<IListingSignalsProps> = ({
    followCount,
}) => {
    const { ratingsData } = useSurfacingExperimentContext();
    if (!ratingsData) return null;

    const _renderRatingsSafe = () => {
        const {
            overall_experience: { average, total } = { average: 0, total: 0 },
        } = ratingsData;
        if (Number(total) < TOTAL_RATING_THRESHOLD) return null;

        return (
            <div className={styles.ratingsRoot}>
                <div className={styles.starRatingRoot}>
                    <StarIcon role="img" />
                    <span>{Number(average).toFixed(1)}</span>
                </div>
                <strong>
                    {numberFormatter(Number(total))} {gettext('ratings')}
                </strong>
            </div>
        );
    };

    const _renderFollowersSafe = () => {
        if (followCount < FOLLOWERS_THRESHOLD) return null;
        return (
            <div className={styles.followersRoot}>
                <p>
                    <strong>{getShortenedNumber(followCount)}</strong>
                    {gettext('following this creator')}
                </p>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            {_renderRatingsSafe()}
            {_renderFollowersSafe()}
        </div>
    );
};
