import React from 'react';

import { ContactOrganizerButton } from '@eventbrite/contact-organizer-button';
import { FollowButton } from '../../FollowButton';

import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../../constants';

import type { OrganizerPanelLayoutProps } from '../../../OrganizerPanel/OrganizerPanelLayout';
import type { DescriptiveOrganizerInfoProps } from '../DescriptiveOrganizerInfo';

type TOrganizerInfoActionsProps = Pick<
    DescriptiveOrganizerInfoProps,
    | 'organizerId'
    | 'organizerName'
    | 'captchaKey'
    | 'eventName'
    | 'gaCategory'
    | 'pageArea'
    | 'isAuthenticated'
> &
    Pick<OrganizerPanelLayoutProps, 'eventId'>;

const _getContainerClassName = (organizerName?: string) =>
    organizerName
        ? 'descriptive-organizer-info__actions__variantB variant'
        : 'descriptive-organizer-info__remove-flex';

export const OrganizerInfoActions: React.FC<TOrganizerInfoActionsProps> = ({
    captchaKey,
    eventName,
    gaCategory,
    isAuthenticated,
    organizerId,
    organizerName,
    eventId,
    pageArea,
}) => {
    const containerClassName = _getContainerClassName(organizerName);

    return (
        <ul className={containerClassName}>
            <li>
                <ContactOrganizerButton
                    isVisible={false}
                    domNodeId="event-page"
                    organizerId={organizerId}
                    organizerName={organizerName}
                    captchaKey={captchaKey}
                    isAuthenticated={isAuthenticated}
                    eventName={eventName}
                    eventId={eventId}
                    gaCategory={gaCategory}
                />
            </li>
            {organizerName ? (
                <li>
                    <FollowButton
                        pageArea={
                            pageArea ||
                            LISTING_HEAP_PAGE_AREA_IDS.SIMPLIFIED_ORGANIZER
                        }
                    />
                </li>
            ) : null}
        </ul>
    );
};
