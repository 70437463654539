import React from 'react';

const StarIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        {...props}
    >
        <path
            d="M8.82475 3.8449C8.93944 3.49232 9.43824 3.49232 9.55293 3.8449L10.9105 8.01838C10.9618 8.17607 11.1088 8.28281 11.2746 8.28281H15.6668C16.0378 8.28281 16.1919 8.75764 15.8917 8.97555L12.339 11.5538C12.2047 11.6513 12.1485 11.8243 12.1998 11.9821L13.557 16.1544C13.6717 16.5071 13.2682 16.8005 12.968 16.5827L9.41372 14.0032C9.2796 13.9059 9.09808 13.9059 8.96396 14.0032L5.40966 16.5827C5.10952 16.8005 4.70598 16.5071 4.82069 16.1544L6.17786 11.9821C6.2292 11.8243 6.17299 11.6513 6.03865 11.5538L2.48599 8.97555C2.18573 8.75764 2.33988 8.28281 2.71087 8.28281H7.1031C7.26893 8.28281 7.4159 8.17607 7.46719 8.01838L8.82475 3.8449Z"
            fill="#F05537"
        />
    </svg>
);

StarIcon.displayName = 'StarIcon';
export default StarIcon;
