import React from 'react';

import { gettext } from '@eventbrite/i18n';
import { removeHtmlTags } from '../helperFunctions';

import { ReadMore, ReadMoreContent, ReadMoreToggle } from '../../ReadMore';

import type {
    PreviewContentLineConfig,
    ReadMoreToggleProps,
} from '../../ReadMore/types';

interface IOrganizerDescriptionProps {
    description: string;
    previewLineCount: PreviewContentLineConfig;
    buttonLabels: ReadMoreToggleProps['labels'];
}

export const OrganizerDescription: React.FC<IOrganizerDescriptionProps> = ({
    description,
    previewLineCount,
    buttonLabels,
}) => {
    const sanitizedText = removeHtmlTags(description);
    if (!sanitizedText?.length) return null;

    return (
        <ReadMore
            ariaLabel={gettext('Organizer description')}
            initialIsExpanded={false}
        >
            <div className="descriptive-organizer-info-mobile__description">
                <ReadMoreContent
                    previewContentType="line"
                    previewLineCount={previewLineCount}
                >
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                </ReadMoreContent>
                <ReadMoreToggle labels={buttonLabels} />
            </div>
        </ReadMore>
    );
};
