import React from 'react';

import { Avatar } from '@eventbrite/eds-avatar';

interface IOrganizerAvatarProps {
    organizerAvatar?: string;
}

export const OrganizerAvatar: React.FC<IOrganizerAvatarProps> = ({
    organizerAvatar,
}) => {
    if (!organizerAvatar) return null;
    return (
        <div
            className="descriptive-organizer-info-mobile__avatar"
            data-testid="organizer-avatar"
        >
            <Avatar imageUrl={organizerAvatar} />
        </div>
    );
};
