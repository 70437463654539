import React, { useEffect, useState } from 'react';

import styles from './RatingInfo.module.scss';

import { Followers } from './Followers';
import { Ratings } from './Ratings';

import { FOLLOWERS_THRESHOLD } from '../../constants';

import type { FC } from 'react';
import type { IRatingInfoProps } from './RatingInfo.type';

export const RatingInfo: FC<IRatingInfoProps> = ({
    starRating,
    totalRating,
    followersCount,
    onRatingClick,
}) => {
    const [showCircleAfterRating, setShowCircleAfterRating] = useState(false);

    useEffect(() => {
        // Currently followers count are rendered after ratings are rendered
        // Hence, once the followers count are rendered, circle after total ratings text will be rendered.
        setShowCircleAfterRating((followersCount ?? 0) > FOLLOWERS_THRESHOLD);
    }, [followersCount]);

    return (
        <div className={styles.container}>
            <Ratings
                starRating={starRating}
                totalRating={totalRating}
                showCircleAfterRating={showCircleAfterRating}
                onRatingClick={onRatingClick}
            />
            <Followers followersCount={followersCount ?? 0} />
        </div>
    );
};
