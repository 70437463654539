import { Typography } from '@eventbrite/marmalade';
import { css, cx } from 'emotion';
import React from 'react';
import { useIsFirstHierarchyLevel } from '../../experimentation';

type PageTitleProps = {
    pageTitle: string;
    hideStartDate: boolean;
};

const titleCustomStyle = (hideStartDate: boolean) => css`
    ${hideStartDate &&
    `
    @media (max-width: 960px) {
        width: 80%;
    }
    `}
`;

export const PageTitle = ({ pageTitle, hideStartDate }: PageTitleProps) => {
    const isFirstHierarchyLevel = useIsFirstHierarchyLevel();

    return (
        <>
            {pageTitle && (
                <>
                    {isFirstHierarchyLevel ? (
                        <Typography
                            as="h1"
                            color="neutral-1000"
                            variant="heading-md"
                        >
                            {pageTitle}
                        </Typography>
                    ) : (
                        <h1
                            className={cx(
                                'event-title',
                                titleCustomStyle(hideStartDate),
                            )}
                        >
                            {pageTitle}
                        </h1>
                    )}
                </>
            )}
        </>
    );
};
