import { EventInteraction } from '@eventbrite/ads';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { getUrgencySignalsForTracking, isPromoCodeActive } from '../../utils';

export const RELATED_EVENTS_PLACEMENT_NAME = 'related_events';
export const LIVE_EVENTS_PLACEMENT_NAME = 'events_carousel';

export function aggregateContext({
    context,
    event,
    events = [],
    page,
}: Omit<EventInteraction, 'action'> & {
    context: any;
    events: FormattedEvent[] | undefined;
    page: { number: number; size: number };
}) {
    const { rank, absoluteRank } = computeEventRanks(event, events);
    const hasOpenPromotion = isPromoCodeActive(event.discount);
    const hasBOGOLabel = !!event.specialDiscounts?.hasBogoTickets;
    const urgencySignals = getUrgencySignalsForTracking(context.urgencySignals);

    return {
        user: {
            id: context.userId,
            locale: context.locale,
            guestId: context.guestId,
            sessionId: context.sessionId,
        },
        adId: event?.promotedListingMetadata?.adId,
        place: {
            name: RELATED_EVENTS_PLACEMENT_NAME,
            // This is a bit confusing, due to historial reasons the rank in this interface works as follows
            // For the first page, we have the relative ranks as detailed in the function bellow
            // For page 1 and upwards we have the absolute rank relative to the page.
            // Previous implementation can be found here: https://github.com/eventbrite/eb-ui/pull/20713/files#diff-f7e7e9797d883b7cead37c9065cd661b1394fa080d20121273962105786a53a0L254
            position:
                page.number === 0
                    ? rank
                    : absoluteRank - page.number * page.size,
            absoluteRank,
            page: page.number + 1,
            context: {
                related_event_id: context.event?.id,
                is_online: context.event?.isOnline,
                place_id: context.event?.localityPlaceId,
            },
            tabKey: '',
            subInterface: {
                name: LIVE_EVENTS_PLACEMENT_NAME,
                position: '',
            },
            placementId: event?.promotedListingMetadata?.placementId,
        },
        displayContext: {
            hasOpenPromotion,
            hasBOGOLabel,
            urgencySignals,
        },
        venueId: event?.venue?.id,
    };
}

/* Due to historical reasons we need to compute both the relative position of an ad and its absolute
 * The relative position (rank) is assuming promoted and organic ads are different lists and reset their rank
 * For example, if we had this series of events: 1Promoted - 2Organic - 3Organic - 4Promoted
 * Their positions would be as follows:
 * 1Promoted - rank: 1, absoluteRank: 1
 * 2Organic - rank: 1, absoluteRank: 2
 * 3Organic - rank: 2, absoluteRank: 3
 * 4Promoted - rank 2, absoluteRank: 4
 * TODO: Unify this approach and have only one rank (absoluteRank)
 */
export function computeEventRanks(
    eventToSearch: FormattedEvent | undefined,
    events: FormattedEvent[],
) {
    if (!eventToSearch) {
        return {
            rank: 0,
            absoluteRank: 0,
        };
    }

    const { isPromoted, id } = eventToSearch;
    const eventsGroup = events.filter((e) => isPromoted === e.isPromoted);

    return {
        rank: eventsGroup.findIndex((e) => e.id === id) + 1,
        absoluteRank: events.findIndex((e) => e.id === id) + 1,
    };
}
