import { gettext } from '@eventbrite/i18n';
import {
    DurationIcon,
    IDIcon,
    MapPinIcon,
    ParkingIcon,
    RefundIcon,
    Typography,
} from '@eventbrite/marmalade';
import React, { VFC } from 'react';
import { LISTING_HEAP_IDS } from '../../../constants';
import { useScrollDispatch } from '../../../contexts/ScrollContext';
import { HighlightDurationValue } from '../types';
import { formatDuration } from '../utils';

type StringValue = {
    value: string;
};
type BooleanValue = {
    value: boolean;
};
type DurationValue = {
    value: HighlightDurationValue;
};

type HighlightComponent<T> = React.FC<
    T & {
        className: string;
    }
>;

const GenericHighlight = ({
    text,
    Icon,
    className,
}: {
    text: string;
    Icon: VFC;
    className: string;
}) => {
    if (!text) {
        return null;
    }

    return (
        <span className={className}>
            <Icon />
            <Typography>{text}</Typography>
        </span>
    );
};

export const LocationHighlight: HighlightComponent<StringValue> = ({
    value,
    className,
}) => {
    const dispatch = useScrollDispatch();
    return (
        <button
            data-heap-id={LISTING_HEAP_IDS.HIGHLIGHT_LOCATION_CLICK}
            onClick={() =>
                dispatch({ action: 'scroll', target: 'whenAndWhere' })
            }
        >
            <GenericHighlight
                className={className}
                text={value}
                Icon={MapPinIcon}
            />
        </button>
    );
};

export const DurationHighlight: HighlightComponent<DurationValue> = ({
    value,
    className,
}) => {
    return (
        <GenericHighlight
            className={className}
            text={value && formatDuration(value).toString()}
            Icon={DurationIcon}
        />
    );
};

export const AgeRestrictionHighlight: HighlightComponent<StringValue> = ({
    value,
    className,
}) => {
    return (
        <GenericHighlight className={className} text={value} Icon={IDIcon} />
    );
};

export const RefundPolicyHighlight: HighlightComponent<BooleanValue> = ({
    className,
}) => {
    const dispatch = useScrollDispatch();
    return (
        <button
            data-heap-id={LISTING_HEAP_IDS.HIGHLIGHT_REFUND_CLICK}
            onClick={() =>
                dispatch({ action: 'scroll', target: 'refundPolicy' })
            }
        >
            <GenericHighlight
                className={className}
                text={gettext('Refund policy info').toString()}
                Icon={RefundIcon}
            />
        </button>
    );
};

export const ParkingHighlight: HighlightComponent<BooleanValue> = ({
    className,
}) => {
    return (
        <GenericHighlight
            className={className}
            text={gettext('Parking').toString()}
            Icon={ParkingIcon}
        />
    );
};
