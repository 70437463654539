import * as React from 'react';

const CameraGraphicSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 144 144" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <path d="M0 0h144v144H0z" />
                <path
                    d="M57.625 41.87h54.625v20.087H57.625V41.87zM31.75 53.348h25.875v8.609H31.75v-8.61z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
                <path
                    d="M115.125 105h-86.25V61.957h86.25V105zm-83.375-2.87h80.5V64.826h-80.5v37.304z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <path
                    fill="#F16932"
                    fillRule="nonzero"
                    d="M31.75 64.826h80.5v37.304h-80.5z"
                />
                <path
                    fill="#3A3A3A"
                    fillRule="nonzero"
                    d="M115.125 63.391h-2.875V41.87H57.625v11.478H31.75V63.39h-2.875V50.478H54.75V39h60.375"
                />
                <ellipse
                    fill="#FFF"
                    fillRule="nonzero"
                    cx={77.75}
                    cy={73.435}
                    rx={24.438}
                    ry={24.391}
                />
                <path
                    d="M77.75 98.543c-13.8 0-25.156-11.334-25.156-25.108 0-13.774 11.356-25.109 25.156-25.109 13.8 0 25.156 11.335 25.156 25.109 0 13.774-11.356 25.108-25.156 25.108zm0-48.782c-13.081 0-23.719 10.617-23.719 23.674 0 13.056 10.638 23.674 23.719 23.674 13.081 0 23.719-10.618 23.719-23.674 0-13.057-10.638-23.674-23.719-23.674z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M30.313 40.435h20.125v5.739H30.313z"
                />
                <path
                    d="M51.156 46.891H29.594v-7.174h21.562v7.174zm-20.125-1.434H49.72v-4.305H31.03v4.305z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <path
                    d="M51.875 47.609h-23V39h23v8.609zm-20.125-2.87H49v-2.87H31.75v2.87z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <ellipse
                    fill="#FFF"
                    fillRule="nonzero"
                    cx={77.75}
                    cy={73.435}
                    rx={11.5}
                    ry={11.478}
                />
                <path
                    d="M77.75 85.63c-6.756 0-12.219-5.452-12.219-12.195 0-6.744 5.463-12.196 12.219-12.196 6.756 0 12.219 5.452 12.219 12.196 0 6.743-5.463 12.195-12.219 12.195zm0-22.956c-5.894 0-10.781 4.878-10.781 10.76 0 5.883 4.887 10.762 10.781 10.762 5.894 0 10.781-4.879 10.781-10.761 0-5.883-4.887-10.761-10.781-10.761zM26 61.957h4.313v2.87H26zM112.25 61.957H118v2.87h-5.75zM38.938 64.109h2.875v15.065h-2.875zM38.938 46.174h2.875v12.913h-2.875z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <path
                    d="M77.75 99.26c-14.231 0-25.875-11.62-25.875-25.825S63.519 47.609 77.75 47.609s25.875 11.621 25.875 25.826c0 14.204-11.644 25.826-25.875 25.826zm0-48.782c-12.65 0-23 10.33-23 22.957 0 12.626 10.35 22.956 23 22.956s23-10.33 23-22.956-10.35-22.957-23-22.957z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <path
                    d="M77.75 54.783c-10.35 0-18.688 8.321-18.688 18.652 0 10.33 8.338 18.652 18.688 18.652s18.688-8.322 18.688-18.652c0-10.33-8.338-18.652-18.688-18.652zm0 28.695c-5.606 0-10.063-4.448-10.063-10.043 0-5.596 4.457-10.044 10.063-10.044 5.606 0 10.063 4.448 10.063 10.044 0 5.595-4.457 10.043-10.063 10.043z"
                    fill="#D2D6DF"
                    fillRule="nonzero"
                />
                <path
                    d="M77.75 93.522c-11.069 0-20.125-9.04-20.125-20.087 0-11.048 9.056-20.087 20.125-20.087s20.125 9.039 20.125 20.087-9.056 20.087-20.125 20.087zm0-37.305c-9.487 0-17.25 7.748-17.25 17.218 0 9.47 7.763 17.217 17.25 17.217S95 82.904 95 73.435c0-9.47-7.763-17.218-17.25-17.218z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
                <path
                    d="M77.75 86.348c-7.188 0-12.938-5.74-12.938-12.913 0-7.174 5.75-12.913 12.938-12.913 7.188 0 12.938 5.739 12.938 12.913s-5.75 12.913-12.938 12.913zm0-22.957c-5.606 0-10.063 4.448-10.063 10.044 0 5.595 4.457 10.043 10.063 10.043 5.606 0 10.063-4.448 10.063-10.043 0-5.596-4.457-10.044-10.063-10.044z"
                    fill="#3A3A3A"
                    fillRule="nonzero"
                />
            </g>
        </g>
    </svg>
);

CameraGraphicSvg.displayName = 'CameraGraphicSvg';
export default CameraGraphicSvg;
