import React from 'react';

import { SocialLinksStyle } from '../../../../types';

import { SocialLinks } from '../../../SocialLinks';

interface IOrganizerSocialHandlesProps {
    website?: string;
    twitter?: string;
    facebook?: string;
}

export const OrganizerSocialHandles: React.FC<IOrganizerSocialHandlesProps> = ({
    facebook,
    twitter,
    website,
}) => {
    if (!facebook && !twitter && !website) return null;

    return (
        <div className="descriptive-organizer-info-mobile__social-links variant">
            <SocialLinks
                orgWebsite={website}
                orgTwitter={twitter}
                orgFacebook={facebook}
                style={SocialLinksStyle.rounded}
            />
        </div>
    );
};
