import { Icon } from '@eventbrite/eds-icon';
import { FlagChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React, { useContext, useState } from 'react';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts/EventContext';
import { CountryProps } from '../../types';
import './ReportThisEventButton.scss';
import { ReportThisEventController } from './ReportThisEventController';

export const ReportThisEventButton: React.FC<CountryProps> = ({ country }) => {
    const { id: eventId } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;

    const [visible, setVisible] = useState(false);
    return (
        <>
            <div className="report-event-button">
                <Button variant="ghost" onClick={() => setVisible(true)}>
                    <div className="button-inner">
                        <Icon type={<FlagChunky />} size="small" />
                        <span>{gettext('Report this event')}</span>
                    </div>
                </Button>
            </div>
            <ReportThisEventController
                event={{ id: eventId }}
                isReportThisEventVisible={visible}
                setIsReportThisEventVisible={setVisible}
                country={country}
            />
        </>
    );
};
