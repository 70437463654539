import classNames from 'classnames';
import React from 'react';
import { LISTING_HEAP_IDS } from '../../../constants';
import { useIsFirstHierarchyLevel } from '../../../experimentation';
import './ActionsPanel.scss';

type ActionsPanelProps = {
    isShareable: boolean;
    isEnded: boolean;
    isSalesEnded: boolean;
    isSoldOut: boolean;
};

export const ActionsPanel = ({
    isShareable,
    isEnded,
    isSalesEnded,
    isSoldOut,
}: ActionsPanelProps) => {
    const canBeShared = isShareable && !isEnded;
    const isHierarchyExperimentActive = useIsFirstHierarchyLevel();

    const panelItemsClassNames = classNames(
        'actions-panel__item ',
        { 'eds-fx--fade-in': isHierarchyExperimentActive },
        { 'eds-fx--fade-in-down': !isHierarchyExperimentActive },
        { 'eds-fx--duration-2 fx--delay-14': !canBeShared },
        {
            'actions-panel__item--share eds-fx--duration-1 fx--delay-12':
                canBeShared,
        },
        {
            'listings_icons--margin-bottom': isSalesEnded || isSoldOut,
        },
    );

    return (
        <div className="actions-panel">
            <ul className="actions-panel__list">
                <li className={panelItemsClassNames}>
                    <span id="listings-root__bookmark-desktop"></span>
                </li>
                {canBeShared && (
                    <li
                        data-heap-id={LISTING_HEAP_IDS.SHARE_EVENT}
                        className={panelItemsClassNames}
                    >
                        <span id="listings-root__share-event-modal"></span>
                    </li>
                )}
            </ul>
        </div>
    );
};
