import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { UserIcon } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import { numberFormatter } from '../../utils/utils';
import { FOLLOWERS_THRESHOLD } from '../constants';
import './OrganizerStats.scss';

export enum StyleTypes {
    CONDENSED = 'condensed',
    CONDENSED_FULL_WIDTH = 'condensed-full-width',
    EXPANDED = 'expanded',
    BLOCK_TEXT_VARIANT = 'block-text-variant',
}

type OrganizerStatsProps = {
    displayRating?: boolean;
    followersCount: number | null;
    organizer: {
        id: string;
    };
    style?: StyleTypes;
    textToShow?: GenericLazyString;
    showIcon?: boolean;
};

export const OrganizerStats = ({
    displayRating = false,
    followersCount,
    style = StyleTypes.CONDENSED,
    textToShow = gettext('followers'),
    showIcon = false,
}: OrganizerStatsProps) => {
    const shouldShowNumberFollowers =
        followersCount && followersCount > FOLLOWERS_THRESHOLD;

    if (!displayRating && !shouldShowNumberFollowers) {
        return null;
    }

    const classes = classNames(
        'organizer-stats',
        { 'organizer-stats--expanded': style === StyleTypes.EXPANDED },
        { 'organizer-stats--condensed': style === StyleTypes.CONDENSED },
        {
            'organizer-stats--condensed-full-width':
                style === StyleTypes.CONDENSED_FULL_WIDTH,
        },
        {
            'organizer-stats--block-text-variant':
                style === StyleTypes.BLOCK_TEXT_VARIANT,
        },
    );

    return (
        <div className={classes}>
            {shouldShowNumberFollowers && (
                <>
                    {showIcon && <UserIcon />}
                    <div data-testid="followers-count">
                        <span className="organizer-stats__highlight">
                            {numberFormatter(followersCount)}
                        </span>{' '}
                        <span className="organizer-stats__suffix">
                            {/* Translators: This string is preceded by the {number_of_followers} Ex. 320 followers */}
                            {textToShow}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};
