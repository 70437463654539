import React from 'react';

interface IOrganizerNameLinkProps {
    organizerName?: string;
    organizerUrl: string;
}

export const OrganizerNameLink: React.FC<IOrganizerNameLinkProps> = ({
    organizerName,
    organizerUrl,
}) => {
    if (!organizerName) return null;

    return (
        <div
            className="descriptive-organizer-info-mobile__name"
            data-testid="organizer-name"
        >
            <a
                href={organizerUrl}
                className="descriptive-organizer-info-mobile__name-link"
                target="_blank"
                rel="noreferrer"
            >
                {organizerName}
            </a>
        </div>
    );
};
