import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import classNames from 'classnames';

import { Graphic } from '@eventbrite/eds-graphic';
import { GRAPHIC_TYPE_PROP_TYPE } from '@eventbrite/eds-graphic';
import { Button } from '@eventbrite/eds-button';
import * as constants from './constants';
import { classRange } from '@eventbrite/eds-utils';

import './emptyState.scss';

const Title = ({ children, isDisabled, cssClasses }) => {
    let component = null;

    if (children) {
        const responsiveMargin = `${classRange(
            'mar-top-6',
            'sn',
            'md',
        )} ${classRange('mar-top-8', 'md')}`;

        const className = classNames(
            'eds-empty-state__title',
            'eds-text--center',
            'eds-text-hs',
            {
                'eds-text-color--grey-900': !isDisabled,
                'eds-text-color--grey-500': isDisabled,
            },
            responsiveMargin,
            cssClasses,
        );

        component = <h1 className={className}>{children}</h1>;
    }

    return component;
};

const Description = ({ children, hasTitle, isDisabled, cssClasses }) => {
    let component = null;

    if (children) {
        const responsiveMargin = `${classRange(
            'mar-top-3',
            'sn',
            'md',
        )} ${classRange('mar-top-1', 'md')}`;
        const responsiveMarginNoTitle = `${classRange(
            'mar-top-6',
            'sn',
            'md',
        )} ${classRange('mar-top-8', 'md')}`;
        const className = classNames(
            'eds-empty-state__description',
            'eds-text--center',
            'eds-text-bl',
            {
                'eds-text-color--grey-600': !isDisabled,
                'eds-text-color--grey-400': isDisabled,
                [responsiveMargin]: hasTitle,
                [responsiveMarginNoTitle]: !hasTitle,
            },
            cssClasses,
        );

        component = (
            <div className="eds-align--center">
                <div className={className}>{children}</div>
            </div>
        );
    }

    return component;
};

const Action = ({
    type,
    text,
    action,
    isDisabled,
    id,
    dataSpec,
    customClassNames = '',
    heapId,
}) => {
    let component = null;

    if (text) {
        const responsiveMargin = `${classRange(
            'mar-top-4',
            'sn',
            'md',
        )} ${classRange('mar-top-6', 'md')}`;
        const className = classNames(
            'eds-empty-state__actions',
            'eds-align--center',
            responsiveMargin,
            customClassNames,
        );

        component = (
            <div className={className}>
                <Button
                    onClick={action}
                    style={type}
                    data-spec={dataSpec || id}
                    id={id}
                    disabled={isDisabled}
                    {...(heapId ? { 'data-heap-id': heapId } : {})}
                >
                    {text}
                </Button>
            </div>
        );
    }

    return component;
};

const Actions = ({
    primaryType,
    primaryText,
    onPrimaryAction,
    secondaryType,
    secondaryText,
    onSecondaryAction,
    secondaryId,
    isDisabled,
    primaryHeapId,
    secondaryHeapId,
}) => {
    const PrimaryAction = (
        <Action
            type={primaryType}
            text={primaryText}
            action={onPrimaryAction}
            isDisabled={isDisabled}
            dataSpec="eds-empty-state__primary-button"
            heapId={primaryHeapId}
        />
    );
    if (secondaryText) {
        return (
            <div className="eds-align--center eds-empty-state__actions-container">
                <Action
                    id={secondaryId}
                    type={secondaryType}
                    text={secondaryText}
                    action={onSecondaryAction}
                    isDisabled={isDisabled}
                    customClassNames={'eds-empty-state__first-action'}
                    heapId={secondaryHeapId}
                />
                {PrimaryAction}
            </div>
        );
    }
    return PrimaryAction;
};

export default class EmptyState extends PureComponent {
    static propTypes = {
        /**
         * Graphic to be displayed
         */
        graphicType: GRAPHIC_TYPE_PROP_TYPE,
        /**
         * Title text to be displayed under the graphic
         */
        title: PropTypes.node,
        /**
         * CSS classes to apply custom styling to the title
         */
        titleClassName: PropTypes.string,
        /**
         * Description text to be displayed under the title
         */
        description: PropTypes.node,
        /**
         * CSS classes to apply custom styling to the description
         */
        descriptionClassName: PropTypes.string,
        /**
         * The type of button that should be shown for primary button
         */
        primaryType: PropTypes.oneOf(constants.PRIMARY_TYPES),
        /**
         * The text that should be shown across the primary button
         */
        primaryText: translationPropType,
        /**
         * The function that should be triggered on primary button click
         */
        onPrimaryAction: PropTypes.func,
        /**
         * The type of button that should be shown for secondary button
         */
        secondaryType: PropTypes.oneOf(constants.PRIMARY_TYPES),
        /**
         * The text that should be shown across the secondary button
         */
        secondaryText: translationPropType,
        /**
         * The function that should be triggered on secondary button click
         */
        onSecondaryAction: PropTypes.func,
        /**
         * The id for the secondary button
         */
        secondaryId: PropTypes.string,
        /**
         * Disables the centering of the empty state container
         */
        disableCentering: PropTypes.bool,
        /**
         * Disable Empty state
         */
        isDisabled: PropTypes.bool,
        /**
         * Primary button heap id
         */
        primaryHeapId: PropTypes.string,
        /**
         * Secondary button heap id
         */
        secondaryHeapId: PropTypes.string,
    };

    static defaultProps = {
        graphicType: constants.DEFAULT_GRAPHIC,
        primaryType: constants.DEFAULT_TYPE,
        secondaryType: constants.SECONDARY_DEFAULT_TYPE,
        isDisabled: false,
    };

    render() {
        const {
            graphicType,
            description,
            descriptionClassName,
            primaryType,
            primaryText,
            onPrimaryAction,
            secondaryType,
            secondaryText,
            onSecondaryAction,
            secondaryId,
            disableCentering,
            title,
            titleClassName,
            isDisabled,
            primaryHeapId,
            secondaryHeapId,
        } = this.props;

        const className = classNames('eds-empty-state', {
            'eds-align--center': !disableCentering,
        });

        return (
            <div className={className} data-spec="empty-state">
                <div>
                    <div className="eds-empty-state__graphic eds-align--center">
                        <Graphic
                            hasHalo={true}
                            haloSize="xsmall"
                            type={graphicType}
                            size="small"
                        />
                    </div>
                    <Title isDisabled={isDisabled} cssClasses={titleClassName}>
                        {title}
                    </Title>
                    <Description
                        hasTitle={!!title}
                        isDisabled={isDisabled}
                        cssClasses={descriptionClassName}
                    >
                        {description}
                    </Description>
                    <Actions
                        primaryType={primaryType}
                        primaryText={primaryText}
                        onPrimaryAction={onPrimaryAction}
                        secondaryType={secondaryType}
                        secondaryText={secondaryText}
                        onSecondaryAction={onSecondaryAction}
                        secondaryId={secondaryId}
                        isDisabled={isDisabled}
                        primaryHeapId={primaryHeapId}
                        secondaryHeapId={secondaryHeapId}
                    />
                </div>
            </div>
        );
    }
}
