import React, { type FC } from 'react';

import styles from './OrganizerInfoModalContent.module.scss';

import { OrganizerDescription } from '../../../DescriptiveOrganizerPanelViewVariant/OrganizerDescription';
import { OrganizerHighlight } from '../OrganizerHighlight';
import { OrganizerRating } from '../OrganizerRating';
import { ReviewSummary } from '../ReviewSummary';

import { gettext } from '@eventbrite/i18n';
import type { IOrganizerInfoModalProps } from '../OrganizerInfoModal';

type TOrganizerInfoModalContentProps = Pick<
    IOrganizerInfoModalProps,
    'profileImageUrl' | 'organizerName' | 'organizerDescription' | 'trustSignal'
>;

export const OrganizerInfoModalContent: FC<TOrganizerInfoModalContentProps> = ({
    organizerName,
    organizerDescription,
    profileImageUrl,
    trustSignal,
}) => {
    return (
        <div className={styles.root} data-testid="organizer-info-modal">
            <OrganizerHighlight
                organizerName={organizerName}
                profileImageUrl={profileImageUrl}
                trustSignal={trustSignal}
            />
            <OrganizerDescription
                description={organizerDescription ?? ''}
                previewLineCount={{
                    mobile: 3,
                    tablet: 2,
                    desktop: 2,
                }}
                buttonLabels={{
                    show: gettext('Show more'),
                    hide: gettext('Show less'),
                }}
            />
            <OrganizerRating />
            <ReviewSummary />
        </div>
    );
};
