import { fromPairs, toPairs, includes } from 'lodash';
import { flow, filter } from 'lodash/fp';

const LONGHAND_BREAKS = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
};

const SIZINGS_LIST = ['sn', 'sm', 'sw', 'mn', 'md', 'mw', 'ln', 'lg', 'lw'];

const _getValidIndex = (sizing) => {
    let shortHandSizing = sizing;

    // convert start longhand to shorthand
    if (LONGHAND_BREAKS[sizing]) {
        shortHandSizing = LONGHAND_BREAKS[sizing];
    }

    const index = SIZINGS_LIST.indexOf(shortHandSizing);

    if (index < 0) {
        throw new Error(
            `Incorrect sizing passed to classRange. Must be one of ${SIZINGS_LIST.join(
                ', ',
            )}`,
        );
    }

    return index;
};

/**
 * build a string of breakpoint sizings so you don't have to put a block of them in your code!

        'eds-l-mn-pad-2',
        'eds-l-md-pad-2',
        'eds-l-mw-pad-2',
        'eds-l-ln-pad-2',
        'eds-l-lg-pad-2',
        'eds-l-lw-pad-2',

        turns into

        classRange('pad-2', 'mn')

 * @param  {String} spacing           the class definition you wish to repeat ex: pad-hor-6, mar-right-2, mar-bot-5
 * @param  {String} startSize         starting range of class list
 * @param  {String} stopSize          optional: inclusive end of range
 * @param  {String} base              optional: class base, defaults to "eds-l"
 * @return {String}                   class list spearated by spaces, example "eds-align-"" for alignment classes
 */
export const classRange = (spacing, startSize, stopSize, base = 'eds-l') => {
    let startIndex = _getValidIndex(startSize);
    let endIndex = SIZINGS_LIST.length - 1;

    if (stopSize) {
        endIndex = _getValidIndex(stopSize);
    }

    if (endIndex < startIndex) {
        const temp = startIndex;

        startIndex = endIndex;
        endIndex = temp;
    }

    return SIZINGS_LIST.slice(startIndex, endIndex + 1)
        .map((size) => `${base}-${size}-${spacing}`)
        .join(' ');
};

/**
 * Filters out all key-value pairs that match the chosen value.
 *
 * @props {Object}, object - Object to be filtered
 * @props {String, Integer}, value - Value that should be filtered
 * @return {Object}, Filtered Object
 */
export const filterObjectValuesEqualTo = (sourceObj, targetValue) =>
    flow(
        // convert object into an array of [key, value] pairs
        toPairs,

        // keep the pairs whose value doesn't match the target value
        filter(([, value]) => value !== targetValue),

        // convert the filtered array of pairs back into an object
        fromPairs,
    )(sourceObj);

/**
 * Given a string, insert specific characters at specific indices.
 * @param  {string} originalString The string to transform. '4111111111111111'
 * @param  {string} insertChar     The character to insert. (For example, a space or hyphen)
 * @param  {array}  indices        A list of integers representing where the character should be inserted.
 * @return {string}                A new string with characters inserted: '4111 1111 1111 1111'
 */
export const insertCharAtIndices = (originalString, insertChar, indices) =>
    [...originalString]
        .reduce((memo, value, currentIndex) => {
            memo.push(
                includes(indices, currentIndex)
                    ? `${insertChar}${value}`
                    : value,
            );

            return memo;
        }, [])
        .join('');

/**
 * @param  {string} string    A string of numbers. '41 11-1111-1111-1111 '
 * @return {string}           A new string with any spaces and hyphens removed. '4111111111111111'
 */
export const removeSpacesAndHyphens = (string) => {
    if (string) {
        return string.replace(/[\s|-]/g, '');
    }

    return string;
};
