import { EventInteraction, useTracking } from '@eventbrite/ads';
import { DiscoveryEvent, FormattedEvent } from '@eventbrite/event-renderer';
import React, { useContext } from 'react';
import { TrackingContext } from '../../contexts';

const RELATED_EVENTS_PLACEMENT_NAME = 'related_events';
const EXPIRED_EVENTS_PLACEMENT_NAME = 'event_expired_page';

export type TrackableProps = React.PropsWithChildren<{
    events: FormattedEvent[];
    className?: string;
}>;

export function Trackable({ events, ...otherProps }: TrackableProps) {
    const hasPromotedEvents = events.find(({ isPromoted }) => isPromoted);
    const getTrackingContext = useExpiredEventsTrackingContextFn(events);
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    return <div ref={hasPromotedEvents ? ref : undefined} {...otherProps} />;
}

export function useExpiredEventsTrackingContextFn(events: FormattedEvent[]) {
    const context = useContext(TrackingContext);
    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateContext({
            id,
            event,
            context,
            events,
        });

    return getTrackingContext;
}

export function aggregateContext({
    id,
    context,
    events,
    event: eventData,
}: Omit<EventInteraction, 'action'> & { context: any; events: any }) {
    const { event, absolutePosition } = getEventFromId(
        events,
        id,
        eventData.isPromoted,
    );
    return {
        user: {
            id: context.userId,
            locale: context.locale,
            guestId: context.guestId,
            sessionId: context.sessionId,
        },
        adId: event?.promotedListingMetadata?.adId,
        place: {
            name: RELATED_EVENTS_PLACEMENT_NAME,
            position: absolutePosition,
            page: 1,
            context: {
                related_event_id: context.event?.id,
                is_online: context.event?.isOnline,
                place_id: context.event?.localityPlaceId,
            },
            subInterface: {
                name: EXPIRED_EVENTS_PLACEMENT_NAME,
                position: '',
            },
            placementId: event?.promotedListingMetadata?.placementId,
        },
        venueId: event?.venue?.id,
    };
}

function getEventFromId(
    allEvents: DiscoveryEvent[],
    eventIdToFind: string,
    isPromoted: boolean,
) {
    const promotedEvents = allEvents.filter(
        (event: DiscoveryEvent) => event?.promotedListingMetadata,
    );
    const organicEvents = allEvents.filter(
        (event: DiscoveryEvent) => !event?.promotedListingMetadata,
    );
    if (isPromoted) {
        const eventPosition = promotedEvents.findIndex(
            (event) => event?.id === eventIdToFind,
        );
        return {
            event: promotedEvents[eventPosition],
            absolutePosition: eventPosition + 1,
        };
    }
    const eventPosition = organicEvents.findIndex(
        (event) => event?.id === eventIdToFind,
    );
    return {
        event: organicEvents[eventPosition],
        absolutePosition: eventPosition + 1,
    };
}
