import type {
    IRatingMeta,
    IRatingResponse,
    IRecommended,
    ITagItem,
} from '../../../../../RelatedEvents/types/api';

export type TSummaryItem = { label: string; value: string } | null;

// prettier-ignore
const _hasThreeUserVote = (rating: IRatingMeta | IRecommended) => +rating.total >= 3;

// prettier-ignore
const _getValueRatingSafe = (valueRating: IRatingMeta): TSummaryItem => {
    if (!_hasThreeUserVote(valueRating) || +valueRating.average < 4) return null;
    return { label: 'value', value: Number(valueRating.average).toFixed(1) };
};

// prettier-ignore
const _getRecommendationSafe = (recommendation: IRecommended): TSummaryItem => {
    if (!_hasThreeUserVote(recommendation) || +recommendation.percentage < 80) return null;
    return { label: "recommendation", value: `${Number(recommendation.percentage).toFixed(0)}%` };
};

// prettier-ignore
const _getFilteredTags = (tags: ITagItem[]) => tags.filter((tag) => _hasThreeUserVote(tag.ratings));

const _getTopThreeQualities = (tags: ITagItem[]): TSummaryItem[] => {
    const filteredTags = _getFilteredTags(tags);
    filteredTags.sort((a, b) => +b.ratings.average - +a.ratings.average);
    return filteredTags.slice(0, 3).map((tag) => ({
        label: tag.name.replace(/_/g, ' '),
        value: Number(tag.ratings.average).toFixed(1),
    }));
};

export const getSummaryList = (ratings: IRatingResponse['ratings']) => {
    const summaryList: TSummaryItem[][] = [];
    const valueRating = _getValueRatingSafe(ratings.event_value);
    const recommendation = _getRecommendationSafe(ratings.recommended);
    const topThreeQualities = _getTopThreeQualities(ratings.tags);
    summaryList.push([valueRating, recommendation]);
    summaryList.push([...topThreeQualities]);
    return summaryList;
};
