import { FormattedEvent } from '@eventbrite/event-renderer';
import { GenericLazyString } from '@eventbrite/i18n';

export interface Tab {
    id: string;
    label: GenericLazyString;
    minPrice?: number;
    maxPrice?: number;
    point_radius?: number;
    category?: string;
}

export interface EventCardsMapProps {
    events: FormattedEvent[];
    isAuthenticated: boolean;
    locale: string;
    bucketLabel: string;
}

export const AFF_BUDGET_AND_NEARBY_EVENTS_LIVE = 'ebudgetandbearbylivmlt';

export const LISTING_VIEW_BUDGET_AND_NEARBY_CAROUSEL =
    'Listing - Expired - View - Budget And Nearby Carousel';
