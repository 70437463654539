import { track } from '@eventbrite/datalayer-library';
import React, { useEffect } from 'react';
import { useIntersection } from 'react-use';
import { FormattedEvent } from '../../types';
import {
    EventInteractions,
    EVENT_INTERACTION,
    HEAP_EVENT_CARD_CLICK,
} from './constants';
import type { TrackInteractionCallback } from './types';
import { logStatsigClick } from './utils';

const NULL_REF = { current: null };

export const useTrackImpression = (
    node: React.Ref<HTMLDivElement>,
    trackInteraction: TrackInteractionCallback,
) => {
    const isImpressed = React.useRef<boolean>(false);
    const intersectionRef = isImpressed.current ? NULL_REF : node;
    const intersection = useIntersection(intersectionRef, {
        threshold: 0.25,
        root: null,
    });

    if (
        intersection &&
        intersection.intersectionRatio > 0.25 &&
        !isImpressed.current
    ) {
        trackInteraction(EventInteractions.Impression);
        isImpressed.current = true;
    }
};

export const useTrackMount = (trackInteraction: TrackInteractionCallback) => {
    useEffect(() => {
        trackInteraction(EventInteractions.Mounted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useTrackInteraction = (
    node: React.RefObject<HTMLDivElement>,
    event: FormattedEvent,
    statsigLocationString?: string,
    heapDataAttributes?: {
        paidStatus: string;
        locationSlug: string | null;
        category: string | null;
        eventId: string;
        eventBucketLabel?: string | null;
        hasPromoCode: boolean;
    },
    statsigMetadata?: Record<string, string>,
) => {
    const trackingCallback = React.useCallback<TrackInteractionCallback>(
        (interaction) => {
            //Statsig Click Tracking
            if (interaction === EventInteractions.Click) {
                statsigLocationString &&
                    logStatsigClick(
                        statsigLocationString,
                        event,
                        statsigMetadata,
                    );

                track({
                    eventName: HEAP_EVENT_CARD_CLICK,
                    eventData: {
                        clickEventId: heapDataAttributes?.eventId,
                        eventPaidStatus: heapDataAttributes?.paidStatus,
                        eventLocation: heapDataAttributes?.locationSlug,
                        eventCategory: heapDataAttributes?.category,
                        eventBucketLabel: heapDataAttributes?.eventBucketLabel,
                        hasPromoCode: heapDataAttributes?.hasPromoCode,
                    },
                });
            }

            //Internal Tracking (event agnostic)
            node?.current?.dispatchEvent(
                new CustomEvent(EVENT_INTERACTION, {
                    detail: {
                        id: event.id,
                        action: interaction,
                        event,
                    },
                    bubbles: true,
                }),
            );
        },

        [
            node,
            event,
            statsigLocationString,
            statsigMetadata,
            heapDataAttributes?.eventId,
            heapDataAttributes?.paidStatus,
            heapDataAttributes?.locationSlug,
            heapDataAttributes?.category,
            heapDataAttributes?.eventBucketLabel,
            heapDataAttributes?.hasPromoCode,
        ],
    );

    return trackingCallback;
};
