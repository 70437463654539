import {
    EventCardSaveAction,
    EventCardShareAction,
    FormattedEvent,
    getShareActionProps,
} from '@eventbrite/event-renderer';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { STATSIG_EVENT_NAMES } from '../../experimentation';
import {
    label,
    ShareType,
    trackShareAction,
} from '../../tracking/trackShareAction';
import { GACodes } from '../../types';
import { ListingsEventCard } from '../_shared/EventCard/ListingsEventCard';
import { AFF_NIGHTLIFE_EVENTS, EventCardsMapProps } from './types';

const eventSaveAction = (
    event: FormattedEvent,
    isAuthenticated: boolean,
    shareActionProps: any,
) => (
    <>
        <EventCardSaveAction
            isAuthenticated={!!isAuthenticated}
            savedByYou={event.savedByYou}
            id={event.id}
            eventName={event.name}
            imageUrl={event.imageUrl}
            actionStyle="outline"
            onClick={(saved) =>
                saved &&
                logEvent(STATSIG_EVENT_NAMES.LISTING_NIGHTLIFE_EVENT_LIKE)
            }
        />
        <EventCardShareAction actionStyle="outline" {...shareActionProps} />
    </>
);

export function getEventCardsMap({
    events,
    isAuthenticated,
    locale,
    bucketLabel,
}: EventCardsMapProps): JSX.Element[] {
    return events.map((event: FormattedEvent) => {
        const affCode = AFF_NIGHTLIFE_EVENTS;
        const eventKey = event.promotedListingMetadata?.adId || event.id;
        const shareActionProps = getShareActionProps({
            name: event.name,
            id: event.id,
            url: event.url,
            affCode: affCode,
            onShareItemClick: (id: string) =>
                trackShareAction(
                    label(id as ShareType),
                    GACodes.GA_CATEGORY_EVENT_CARD,
                ),
            onClick: () => {
                trackShareAction(
                    GACodes.GA_LABEL_OPEN_MODAL,
                    GACodes.GA_CATEGORY_EVENT_CARD,
                );
                logEvent(STATSIG_EVENT_NAMES.LISTING_NIGHTLIFE_EVENT_SHARE);
            },
        });

        return (
            <div
                data-heap-id={'listings-nightlife-event-card-click'}
                key={eventKey}
            >
                <ListingsEventCard
                    locale={locale || 'en-US'}
                    event={event}
                    affCode={affCode}
                    onClick={() => {
                        logEvent(
                            STATSIG_EVENT_NAMES.LISTING_NIGHTLIFE_EVENT_CLICK,
                            null,
                        );
                    }}
                    bucketLabel={bucketLabel}
                    actions={eventSaveAction(
                        event,
                        isAuthenticated,
                        shareActionProps,
                    )}
                />
            </div>
        );
    });
}
