import React from 'react';
import {
    AgeRestrictionHighlight,
    DurationHighlight,
    LocationHighlight,
    ParkingHighlight,
    RefundPolicyHighlight,
} from './NewHierarchyHighlightItems';
import type { NewHierarchyHighlightProps } from './types';

import './NewHierarchyHighlights.scss';

type HighlightKey = keyof NewHierarchyHighlightProps;

const highlightComponents: Record<
    HighlightKey,
    React.FC<{
        value: any;
        className: string;
    }>
> = {
    locationName: LocationHighlight,
    duration: DurationHighlight,
    ageRestriction: AgeRestrictionHighlight,
    refundPolicy: RefundPolicyHighlight,
    parking: ParkingHighlight,
};

const sortHighlightKeys = (
    highlights: NewHierarchyHighlightProps,
): HighlightKey[] => {
    const priority = [
        'locationName',
        'duration',
        'ageRestriction',
        'refundPolicy',
        'parking',
    ];
    return Object.keys(highlights).sort(
        (h1, h2) => priority.indexOf(h1) - priority.indexOf(h2),
    ) as HighlightKey[];
};

const NewHierarchyHighlights = (props: NewHierarchyHighlightProps) => {
    const highlightKeys = sortHighlightKeys(props);

    return (
        <ul>
            {highlightKeys
                .map((highlightKey) => {
                    const highlightValue = props[highlightKey];
                    const Component = highlightComponents[highlightKey];

                    if (!Component || !highlightValue) {
                        return;
                    }

                    return (
                        <li key={highlightKey} className="highlight-list-item">
                            <Component
                                value={highlightValue}
                                className="new-hierarchy-highlight-item"
                            />
                        </li>
                    );
                })
                .filter((h) => !!h)}
        </ul>
    );
};

export default NewHierarchyHighlights;
