export function interleaveTwoArrays<T>(
    arrayA: T[],
    arrayB: T[],
    positions: number[],
) {
    let interleavedArray = [...arrayA];

    arrayB.forEach((item, index) => {
        // If no position has been provided, just put the element in the end
        const newPosition = (positions[index] || interleavedArray.length) - 1;

        interleavedArray = interleaveIntoArray(
            interleavedArray,
            item,
            newPosition,
        );
    });

    return interleavedArray;
}

function interleaveIntoArray<T>(array: T[], element: T, position: number): T[] {
    const result = [...array];

    // If the desired position of A is greater than the current length of the array,
    // insert A at the end of the array.
    if (position > result.length) {
        position = result.length;
    }

    result.splice(position, 0, element);

    return result;
}
