import {
    SegmentedCarousel,
    ToggleChipProps,
    useSegmentedCarouselController,
} from '@eventbrite/collection-carousel';
import { EmptyState } from '@eventbrite/eds-empty-state';
import { GhostGraphic } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { getCurrencySymbol } from '@eventbrite/intl';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { Typography } from '@eventbrite/marmalade';
import type { OrganicEventSearchFiltersApiPayload } from '@eventbrite/search-utils';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect, useState } from 'react';
import {
    STATSIG_EVENT_NAMES,
    usePriceAndDistanceCarouselExperiment,
} from '../../experimentation';
import type { LocationData } from '../../types';
import styles from './BudgetAndNearbyEventsCarousel.module.scss';
import { useBudgetAndNearbyEvents } from './BudgetAndNearbyEventsData';
import { countryMapping } from './CountryNameCountryCode';
import { getEventCardsMap } from './getEventCardsMap';
import { categoryRadiusMap, getTabs } from './tabConfig';
import { LISTING_VIEW_BUDGET_AND_NEARBY_CAROUSEL, type Tab } from './types';

const CAROUSEL_HEADING = gettext('Similar vibes');

const PAGE_SIZE = 10;

const renderLoadingSkeleton = () => (
    <div className={styles.loadingSkeleton} data-testid="loading-skeleton">
        <LoadingSkeleton />
    </div>
);

interface BudgetAndNearbyEventsProps {
    isAuthenticated: boolean;
    location: LocationData;
    locale: string;
    category?: string;
    isMobile?: boolean;
}

export const BudgetAndNearbyEventsCarousel: React.FC<
    BudgetAndNearbyEventsProps
> = ({ isAuthenticated, location, locale, category = 'Other', isMobile }) => {
    const pricingExperiment = usePriceAndDistanceCarouselExperiment();
    const segmentedCarouselControllerProps = useSegmentedCarouselController();

    const [currency, setCurrency] = useState({
        name: 'USD',
        symbol: '$',
    });

    const [tabs, setTabs] = useState<Tab[]>([]);
    const [activeTab, setActiveTab] = useState<Tab | null>(null);

    useEffect(() => {
        if (location.country && countryMapping[location.country]) {
            const currencyName = countryMapping[location.country].currency;
            const symbol = getCurrencySymbol(currencyName);
            setCurrency({ name: currencyName, symbol });
            const updatedTabs = getTabs(symbol, category);
            setTabs(updatedTabs);
            setActiveTab(updatedTabs[0] || null);
        }
    }, [location, category]);

    const price_range =
        tabs[0]?.minPrice !== undefined && tabs[0]?.maxPrice !== undefined
            ? {
                  minimum_price: tabs[0].minPrice,
                  maximum_price: tabs[0].maxPrice,
                  currency: currency.name,
              }
            : undefined;

    const budgetQueryOverload: OrganicEventSearchFiltersApiPayload = {
        price: 'paid',
        page_size: PAGE_SIZE,
        aggs: {},
        dates: ['current_future'],
    };

    if (price_range) {
        budgetQueryOverload.price_range = price_range;
    }

    const nearbyQueryOverload: OrganicEventSearchFiltersApiPayload = {
        price: 'paid',
        page_size: PAGE_SIZE,
        aggs: {},
        dates: ['current_future'],
    };

    if (
        tabs[1]?.point_radius !== undefined &&
        location.latitude &&
        location.longitude
    ) {
        const latitude = location.latitude;
        const longitude = location.longitude;
        const unit = 'mi';

        nearbyQueryOverload.point_radius = {
            latitude,
            longitude,
            radius: tabs[1].point_radius + unit,
        };
    }

    if (category !== undefined && categoryRadiusMap[category]) {
        budgetQueryOverload.tags = [categoryRadiusMap[category].id];
        nearbyQueryOverload.tags = [categoryRadiusMap[category].id];
    }

    const {
        data: budgetData,
        isFetching: isFetchingBudget,
        isLoading: isLoadingBudget,
    } = useBudgetAndNearbyEvents({
        queryOverload: budgetQueryOverload,
        queryType: tabs[0]?.id,
    });

    const {
        data: nearbyData,
        isFetching: isFetchingNearby,
        isLoading: isLoadingNearby,
    } = useBudgetAndNearbyEvents({
        queryOverload: nearbyQueryOverload,
        queryType: tabs[1]?.id,
    });

    const budgetEvents = budgetData?.events || [];
    const nearbyEvents = nearbyData?.events || [];

    const handleSelectedTab = (selectedTab: ToggleChipProps) => {
        const foundTab = tabs.find((tab) => tab.id === selectedTab.id);
        if (foundTab) {
            setActiveTab(foundTab);
            logEvent(
                STATSIG_EVENT_NAMES.LISTING_CLICK_BUDGET_AND_NEARBY_CAROUSEL_TAB,
                selectedTab.id,
            );
        } else {
            console.error('Tab not found:', selectedTab.id);
        }
    };

    if (isFetchingBudget || isFetchingNearby) {
        return renderLoadingSkeleton();
    }

    if (
        !pricingExperiment.showCarousel ||
        (!isFetchingBudget &&
            budgetEvents.length === 0 &&
            activeTab?.id === tabs[0]?.id)
    ) {
        return null;
    }

    const eventsToShow =
        activeTab?.id === tabs[0]?.id ? budgetEvents : nearbyEvents;

    const budgetAndNearbyEvents = getEventCardsMap({
        events: eventsToShow,
        isAuthenticated,
        locale,
        bucketLabel: CAROUSEL_HEADING.toString(),
    });

    return (
        <>
            {(isLoadingBudget || isLoadingNearby) && renderLoadingSkeleton()}
            {!isLoadingBudget && !isLoadingNearby && (
                <div
                    data-testid="budget-events-carousel"
                    className={styles.budgetAndNearbyEventsCarousel}
                    data-heap-id={LISTING_VIEW_BUDGET_AND_NEARBY_CAROUSEL}
                >
                    <div className={styles.segmentedCarousel}>
                        <SegmentedCarousel
                            {...{
                                ...segmentedCarouselControllerProps,
                                hideOverflow: !isMobile,
                                rootPaddingTop: 0,
                                rootPaddingBottom: 0,
                                rootPaddingLeft: isMobile ? 20 : 0,
                                rootPaddingRight: isMobile ? 20 : 0,
                                headerGap: 0,
                            }}
                        >
                            <SegmentedCarousel.Header>
                                <div className={styles.segmentedCarouselHeader}>
                                    <div
                                        className={
                                            styles.segmentedCarouselTitle
                                        }
                                    >
                                        <Typography
                                            color="neutral-900"
                                            variant="heading-sm"
                                        >
                                            {CAROUSEL_HEADING}
                                        </Typography>
                                    </div>
                                    <div
                                        className={
                                            styles.segmentedCarouselToggleChips
                                        }
                                    >
                                        <SegmentedCarousel.ToggleChips
                                            header=""
                                            toggleChips={tabs.map((tab) => ({
                                                label: tab.label,
                                                id: tab.id,
                                                isSelected:
                                                    tab.id === activeTab?.id,
                                            }))}
                                            onSelected={handleSelectedTab}
                                        />
                                    </div>
                                </div>
                            </SegmentedCarousel.Header>
                            <div className={styles.segmentedCarouselTrack}>
                                <SegmentedCarousel.Track
                                    key={`budget-and-nearby-events-${
                                        activeTab?.id
                                    }-isLoading-${
                                        isLoadingBudget || isLoadingNearby
                                    }`}
                                >
                                    {budgetAndNearbyEvents}
                                    {eventsToShow.length === 0 && (
                                        <EmptyState
                                            graphicType={<GhostGraphic />}
                                            title={gettext(
                                                "We couldn't find anything",
                                            )}
                                            description={gettext(
                                                'Adjust your filters and try again',
                                            )}
                                        />
                                    )}
                                </SegmentedCarousel.Track>
                            </div>
                        </SegmentedCarousel>
                    </div>
                </div>
            )}
        </>
    );
};
