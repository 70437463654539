import React, { FC } from 'react';

import styles from './SummaryRow.module.scss';

import type { TSummaryItem } from './ReviewSummary.helper';

interface ISummaryRowProps {
    row: TSummaryItem[];
}

export const SummaryRow: FC<ISummaryRowProps> = ({ row }) => {
    if (!row.length || row.every((item) => item == null)) return null;

    return (
        <ul className={styles.summaryList}>
            {row.map((item) =>
                item ? (
                    <li className={styles.summaryListItem} key={item.label}>
                        <p className={styles.summaryValue}>{item.value}</p>
                        <p className={styles.summaryLabel}>{item.label}</p>
                    </li>
                ) : null,
            )}
        </ul>
    );
};
