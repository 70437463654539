import React, { useEffect, useState } from 'react';

import { track } from '@eventbrite/datalayer-library';
import { gettext } from '@eventbrite/i18n';

import {
    useEventBasicInformationContext,
    useSurfacingExperimentContext,
} from '../../../../contexts';
import {
    useFollowButton,
    useFollowCount,
} from '../../FollowButton/FollowButton';

import { LISTING_HEAP_PAGE_AREA_IDS } from '../../../../constants';
import { StyleTypes } from '../../OrganizerStats/OrganizerStats';

import { FollowButton } from '../../FollowButton';
import { OrganizerStats } from '../../OrganizerStats/OrganizerStats';
import { OrganiserListingPageProps } from '../OrganiserListingPage/OrganiserListingPage';
import { LSClickableAvatarWithBadge } from '../OrganizerTrustSignalInfo/ListingsAvatarWithBadge';
import { RatingInfo } from '../RatingInfo';
import { OrganizerInfoModal } from './OrganizerInfoModal';

import './OrganizerTrustSignalInfoVariant.scss';

import { SURFACING_RATING_EXPERIMENT } from '../../../../experimentation/constants';

import type { FeatureFlags } from '../../../../types';

interface OrganizerTrustSignalInfoProps {
    signalType?: string;
    featureFlags?: FeatureFlags;
    showBadge?: boolean;
    isMobile?: boolean;
}

export type TOrganizerTrustSignalInfoVariantProps =
    OrganizerTrustSignalInfoProps & OrganiserListingPageProps;

export const OrganizerTrustSignalInfoVariant = ({
    organizerName,
    organizerId,
    trustSignal,
    organizerDescription,
    pageArea,
    organizerAvatar,
    featureFlags,
    showBadge,
    isMobile = false,
}: TOrganizerTrustSignalInfoVariantProps) => {
    const { ratingsData } = useSurfacingExperimentContext();
    const { id: eventId } = useEventBasicInformationContext();
    const followersCount = useFollowCount();
    const { shouldShowLoginModal } = useFollowButton();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!ratingsData?.overall_experience) return;
        track({
            eventName: SURFACING_RATING_EXPERIMENT.experimentLoaded,
            eventData: {
                organizerId,
                eventId,
                rating: Number(ratingsData.overall_experience.average),
                totalRatings: Number(ratingsData.overall_experience.total),
            },
        });
    }, [ratingsData?.overall_experience, organizerId, eventId]);

    useEffect(() => {
        if (shouldShowLoginModal) {
            setShowModal(false);
        }
    }, [shouldShowLoginModal]);

    const trackModalClose = () => {
        track({
            eventName: SURFACING_RATING_EXPERIMENT.modalClose,
            eventData: {},
        });
    };

    const trackCardClickForMobile = () => {
        track({
            eventName: SURFACING_RATING_EXPERIMENT.cardClick,
            eventData: {},
        });
    };

    const trackModalOpenForDesktop = () => {
        track({
            eventName: SURFACING_RATING_EXPERIMENT.modalOpen,
            eventData: {},
        });
    };

    const renderFollowButtonSection = (dataTestId: string) => (
        <div
            className="organizer-listing-info-variant-b_follow-button"
            data-testid={dataTestId}
        >
            <FollowButton
                pageArea={
                    pageArea || LISTING_HEAP_PAGE_AREA_IDS.SIMPLIFIED_ORGANIZER
                }
                shouldPreventPropogation={isMobile}
            />
        </div>
    );

    const renderFollowerCount = (className: string, dataTestId: string) => (
        <div className={className} data-testid={dataTestId}>
            {renderOrganiserStat()}
        </div>
    );

    const renderOrganiserStat = () => (
        <OrganizerStats
            organizer={{ id: organizerId }}
            followersCount={followersCount || null}
            style={StyleTypes.BLOCK_TEXT_VARIANT}
        />
    );

    return (
        <>
            <section
                className="organizer-listing-info-variant-b organizer-listing-root"
                aria-label="Organizer profile"
                onClick={() => {
                    if (isMobile) {
                        setShowModal(true);
                        trackCardClickForMobile();
                    }
                }}
            >
                <div className="organizer-listing-info-variant-b__profile reverse-image">
                    <div className="organizer-listing-info-avatar-with-follower">
                        <LSClickableAvatarWithBadge
                            organizerId={organizerId}
                            organizerAvatar={organizerAvatar}
                            featureFlags={featureFlags}
                            showBadge={showBadge}
                            isMobile={isMobile}
                        />

                        {renderFollowerCount(
                            'organizer-listing-info-variant-b__followers-mobile-version',
                            'followers-count-mobile',
                        )}
                    </div>

                    <div
                        className="organizer-listing-info-variant-b__details"
                        data-testid="organizer-info-details"
                    >
                        <div className="organizer-listing-info-variant-b__card-info">
                            {organizerName && (
                                <span className="organizer-listing-info-variant-b__name-by include-center">
                                    {/* Translators: Word "By" followed by the organizer's name, ex: By My Cool Organizer Name */}
                                    {gettext('By')}{' '}
                                    <strong className="organizer-listing-info-variant-b__name-link">
                                        {organizerName}
                                    </strong>
                                </span>
                            )}
                        </div>
                        <RatingInfo
                            starRating={Number(
                                ratingsData?.overall_experience.average,
                            )}
                            totalRating={Number(
                                ratingsData?.overall_experience.total,
                            )}
                            followersCount={followersCount ?? 0}
                            onRatingClick={() => {
                                if (!isMobile) {
                                    setShowModal(true);
                                    trackModalOpenForDesktop();
                                }
                            }}
                        />
                        <div className="organizer-listing-info-variant-b__follow-button-mobile">
                            {renderFollowButtonSection('follow-button-mobile')}
                        </div>
                    </div>
                </div>
                <div className="organizer-listing-info-variant-b__follow-button-desktop">
                    {renderFollowButtonSection('follow-button-desktop')}
                </div>
            </section>
            <OrganizerInfoModal
                isMobile={isMobile}
                showModal={showModal}
                trustSignal={trustSignal}
                profileImageUrl={organizerAvatar}
                organizerName={organizerName}
                organizerDescription={organizerDescription}
                onClose={() => {
                    setShowModal(false);
                    trackModalClose();
                }}
            />
        </>
    );
};
