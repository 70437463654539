import React, { useEffect, useState, type FC, type ReactNode } from 'react';

import { gettext } from '@eventbrite/i18n';
import { getDrawerHeight } from './OrganizerInfoModal.helper';

import { Modal } from '@eventbrite/eds-modal';

import { OrganizerInfoModalContent } from './OrganizerInfoModalContent';
import { OrganizerInfoModalFooter } from './OrganizerInfoModalFooter';

import { ScrollableDrawer } from '../../../../ScrollableDrawer/ScrollableDrawer';
import styles from './OrganizerInfoModal.module.scss';

export interface IOrganizerInfoModalProps {
    isMobile: boolean;
    showModal: boolean;
    organizerName?: string;
    trustSignal?: ReactNode;
    profileImageUrl?: string;
    organizerDescription?: string;
    onClose: () => void;
}

export const OrganizerInfoModal: FC<IOrganizerInfoModalProps> = ({
    organizerName,
    organizerDescription,
    profileImageUrl,
    trustSignal,
    showModal,
    isMobile,
    onClose,
}) => {
    const [mobileInnerHeight, setMobileInnerHeight] = useState(getDrawerHeight);

    useEffect(() => {
        if (isMobile) {
            setMobileInnerHeight(getDrawerHeight);
        }
    }, [isMobile]);

    if (!showModal) return null;

    if (isMobile) {
        return (
            <ScrollableDrawer
                onClickOutside={onClose}
                heightFixed={`${mobileInnerHeight}px`}
            >
                <OrganizerInfoModalContent
                    organizerName={organizerName}
                    organizerDescription={organizerDescription}
                    profileImageUrl={profileImageUrl}
                    trustSignal={trustSignal}
                />
                <OrganizerInfoModalFooter />
            </ScrollableDrawer>
        );
    }

    return (
        <Modal
            title={gettext('About the organiser')}
            onClose={onClose}
            onPaneClickOutside={onClose}
            isShown={showModal}
            noMinHeight
            isPortal
            isClosable
            noPadding
            modalHostNodeSelector="body"
            __containerClassName={styles.infoModal}
        >
            <OrganizerInfoModalContent
                organizerName={organizerName}
                organizerDescription={organizerDescription}
                profileImageUrl={profileImageUrl}
                trustSignal={trustSignal}
            />
            <OrganizerInfoModalFooter />
        </Modal>
    );
};
