import React, {
    Children,
    ReactChild,
    ReactFragment,
    ReactPortal,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { SLIDE_DIRECTIONS } from './constants';
import { SegmentedCarouselContext } from './context';
import { initDefaults } from './defaults';
import styles from './SegmentedCarousel.module.scss';
import { SegmentedCarouselSegment } from './SegmentedCarouselSegment';
import { debounce } from './utils';

export interface SegmentedCarouselTrackProps {
    children: React.ReactNode;
}

interface SegmentedCarouselTrackPagesProps {
    segments: ReactChild | ReactFragment | ReactPortal;
    key: string;
}

export const SegmentedCarouselTrack: React.FC<SegmentedCarouselTrackProps> = ({
    children,
}: SegmentedCarouselTrackProps): JSX.Element => {
    const {
        defaults,
        rootWidth,
        syncedPadding,
        shouldPaginate,
        slideTo,
        setSlideTo,
        shouldUpdateCarousel,
        setShouldUpdateCarousel,
        currentPage,
        setCurrentPage,
        setIsFirstPage,
        setIsLastPage,
        visibleSegments,
        rootPaddingLeft,
        rootPaddingRight,
        hideOverflow,
    } = useContext(SegmentedCarouselContext);
    const trackWrapperRef = useRef<HTMLDivElement | null>(null);
    const trackRef = useRef<HTMLDivElement | null>(null);
    const [segmentWidth, setSegmentWidth] = useState<number>(0);
    const [tempVisibleSegments, setTempVisibleSegments] = useState<number>(0);
    const [pageWidth, setPageWidth] = useState<number>(0);
    const [lastPageWidth, setLastPageWidth] = useState<number>(0);
    const [lastPageVisibleSegments, setLastPageVisibleSegments] =
        useState<number>(0);
    const [pages, setPages] = useState<SegmentedCarouselTrackPagesProps[]>([]);
    const [touchedTrack, setTouchedTrack] = useState<boolean>(false);
    const [arrayChildren, setArrayChildren] = useState<
        (ReactChild | ReactFragment | ReactPortal)[]
    >(Children.toArray(children));
    const [timer, setTimer] = useState<number>(0);
    const trackWrapperClasses = classNames(
        styles.trackWrapper,
        'segmented-carousel__track-wrapper',
    );
    const trackClasses = classNames(styles.track, 'segmented-carousel__track');
    const pageClasses = classNames(styles.page, 'segmented-carousel__page');

    useEffect(() => {
        const childrenToArray = Children.toArray(children);
        if (childrenToArray.length) {
            setArrayChildren(childrenToArray);
        }
    }, [children]);

    /**
     * set segments and page widths
     */
    useEffect(() => {
        if (rootWidth) {
            const paddingLessRootWidth =
                rootWidth - rootPaddingLeft - rootPaddingRight;

            setSegmentWidth(
                paddingLessRootWidth / visibleSegments - syncedPadding * 2,
            );

            /*
            page width is the size of visible segments grouped together
            or each individual segment if pagination is false
            */
            setPageWidth(
                shouldPaginate
                    ? paddingLessRootWidth
                    : segmentWidth + syncedPadding * 2,
            );
        }

        // Get the last page width and use that to snap the last page to the end of the tracker
        if (
            pages.length &&
            segmentWidth &&
            shouldPaginate &&
            (!lastPageWidth ||
                shouldUpdateCarousel ||
                tempVisibleSegments !== visibleSegments)
        ) {
            const lastPage = pages[pages.length - 1];
            const remainingSegments = Children.toArray(
                lastPage.segments,
            ).length;
            setLastPageVisibleSegments(remainingSegments);
            setTimeout(() => {
                setLastPageWidth(
                    (segmentWidth + syncedPadding * 2) * remainingSegments,
                );
            }, 100);
            setTempVisibleSegments(visibleSegments);
        }
    }, [
        children,
        rootWidth,
        rootPaddingLeft,
        rootPaddingRight,
        pages,
        syncedPadding,
        visibleSegments,
        tempVisibleSegments,
        shouldPaginate,
        segmentWidth,
        shouldUpdateCarousel,
        lastPageWidth,
        setSegmentWidth,
        setPageWidth,
        setTempVisibleSegments,
        setLastPageVisibleSegments,
        setLastPageWidth,
    ]);

    /**
     * paginate the segments by visibleSegments.
     * If shouldPaginate is false, then each segment is wrapped in a page.
     */
    useEffect(() => {
        if (
            arrayChildren.length &&
            (!pages.length || tempVisibleSegments !== visibleSegments)
        ) {
            let pagesUnindexed = arrayChildren;

            // group segments into pages by visibleSegments
            if (shouldPaginate) {
                const pagesLength = Math.ceil(
                    arrayChildren.length / visibleSegments,
                );
                pagesUnindexed = Array.from(
                    { length: pagesLength },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    (value, index) =>
                        arrayChildren.slice(
                            index * visibleSegments,
                            index * visibleSegments + visibleSegments,
                        ),
                );
            }

            // add indexing for keys in the React list
            const pagesIndexed = pagesUnindexed.map((p, i) => ({
                segments: p,
                key: i.toString(),
            }));
            setPages(pagesIndexed);
            setTempVisibleSegments(visibleSegments);
        }
    }, [
        pages,
        visibleSegments,
        tempVisibleSegments,
        shouldPaginate,
        shouldUpdateCarousel,
        arrayChildren,
        setPages,
        setTempVisibleSegments,
    ]);

    /**
     * check to see if all segments fit within the visible container and disables controls
     */
    useEffect(() => {
        if (rootWidth && pages.length) {
            const noScroll =
                rootWidth - rootPaddingLeft - rootPaddingRight >=
                pages.length * pageWidth;
            setTimeout(() => {
                setIsLastPage(noScroll);
            });
        }
    }, [
        pageWidth,
        pages.length,
        rootPaddingLeft,
        rootPaddingRight,
        rootWidth,
        shouldUpdateCarousel,
        setIsLastPage,
    ]);

    /**
     * slide the track whenever slideTo is triggered. This includes internal and external nav controls
     */
    useEffect(() => {
        if (trackWrapperRef.current && slideTo) {
            let nextPage = 0;

            const lastPage = currentPage === pages.length - 1;
            if (slideTo === SLIDE_DIRECTIONS.PREVIOUS) {
                nextPage =
                    !shouldPaginate && lastPage
                        ? currentPage - visibleSegments
                        : currentPage - 1;
            }

            if (slideTo === SLIDE_DIRECTIONS.NEXT) {
                nextPage = currentPage + 1;
            }

            const trackLeftPosition = pageWidth * nextPage;

            // slide the track
            // eslint-disable-next-line no-param-reassign
            trackWrapperRef.current.scrollTo({
                left: trackLeftPosition,
                behavior: 'smooth',
            });
        }
    }, [
        trackWrapperRef,
        slideTo,
        pageWidth,
        visibleSegments,
        currentPage,
        pages,
        shouldPaginate,
    ]);

    /**
     * on shouldUpdateCarousel, reset/ update the track
     */
    useEffect(() => {
        if (shouldUpdateCarousel) {
            setPages([]);
            setArrayChildren(Children.toArray(children));
            setTimeout(() => {
                setCurrentPage(0);
                setSlideTo('');
                setIsFirstPage(true);
                setIsLastPage(false);
                setShouldUpdateCarousel(false);
                if (trackWrapperRef.current) {
                    // slide track back to start
                    // eslint-disable-next-line no-param-reassign
                    trackWrapperRef.current.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    }, [
        trackWrapperRef,
        shouldUpdateCarousel,
        children,
        setArrayChildren,
        setCurrentPage,
        setIsFirstPage,
        setIsLastPage,
        setPages,
        setSlideTo,
        setShouldUpdateCarousel,
    ]);

    /**
     * handles tabbing
     */
    const handleKeyUp = ({
        e,
        pageIndex,
    }: {
        e: React.KeyboardEvent<HTMLDivElement>;
        pageIndex: number;
    }) => {
        if (e.target instanceof Element && e.key === 'Tab') {
            e.preventDefault();
            if (shouldPaginate) {
                if (
                    e.shiftKey &&
                    /*
                    NOTE: If there are focusable elements within a segment, on Shift + click,
                    the scrollbar will abruptly move the segment into view. This action
                    changes the current page index and triggers the next shift click to move
                    to the next page, when it should keep the user within the segment.
                    To prevent this behavior, the solution is to check for the first segment
                    on the page instead of the last segment. This approach is the most effective
                    as we cannot alter the scrollbar behavior when focusing on elements. Also,
                    checking for the specific attribute helps to not trigger a scroll when inner
                    elements are focused.
                    */
                    e.target?.getAttribute('data-isfirstsegmentinpage') ===
                        'true'
                ) {
                    setSlideTo?.(SLIDE_DIRECTIONS.PREVIOUS);
                } else if (
                    !e.shiftKey &&
                    e.target?.getAttribute('data-isfirstsegmentinpage') ===
                        'true'
                ) {
                    setSlideTo?.(SLIDE_DIRECTIONS.NEXT);
                }
            } else if (pages.length && visibleSegments && pageIndex) {
                if (e.shiftKey && pageIndex < pages.length - visibleSegments) {
                    setSlideTo?.(SLIDE_DIRECTIONS.PREVIOUS);
                } else if (!e.shiftKey && pageIndex > visibleSegments - 1) {
                    setSlideTo?.(SLIDE_DIRECTIONS.NEXT);
                }
            }
        }
    };

    /**
     * Main carousel functionality that runs anytime the carousel is scrolled.
     */
    const handleOnScroll = (e: React.UIEvent<HTMLElement>): void => {
        /*
        The user can both scroll the carousel and click the nav controls.
        This functionality gets the page index as the carousel is scrolled,
        and allows both actions to be used interchangeably.
        */
        if (e?.target instanceof Element) {
            const { target } = e;
            const pageIndexAsFloat = target.scrollLeft / pageWidth;
            // split decimal from number
            const pageIndexAsStringArray = `${pageIndexAsFloat}`.split('.');
            // get whole number
            const index = parseInt(pageIndexAsStringArray[0], 10);
            // get decimal
            const threshold = parseInt(
                pageIndexAsStringArray?.[1]?.[0] ?? 0,
                10,
            );
            // if threshold (decimal) is greater than 5 (.5), then increment index
            let nextPage = threshold >= 5 ? index + 1 : index;
            /*
            however, if the end of the scroll is reached, then increment index
            This is important because the last visible segments may not be the same
            amount as the previous ones and the threshold may not be accurate.
            */
            if (
                trackWrapperRef.current &&
                trackWrapperRef.current.offsetWidth +
                    trackWrapperRef.current.scrollLeft >=
                    trackWrapperRef.current.scrollWidth
            ) {
                nextPage = pages.length - 1;
            }

            const firstPage = nextPage === 0;
            const lastPage =
                Math.ceil(pageIndexAsFloat) === pages.length - 1 ||
                nextPage === pages.length - 1;

            // check if next page is the first or last page
            // setting both in cases where first and last page are both visible in large container
            setIsFirstPage(firstPage);
            setIsLastPage(lastPage);

            // set next page
            if (currentPage !== nextPage) {
                setSlideTo('');
                setCurrentPage(nextPage);
            }
            setTimer(slideTo ? 20 : 100);
            handleEndScroll({ nextPage, lastPage });
        }
    };

    /**
     * End of scroll functionality
     */
    const handleEndScroll = useMemo(
        () =>
            debounce(
                ({
                    nextPage,
                    lastPage,
                }: {
                    nextPage: number;
                    lastPage: boolean;
                }) => {
                    // snap current page into position when scrolling on touch devices
                    if (trackWrapperRef.current && !slideTo && touchedTrack) {
                        const trackLeftPosition = lastPage
                            ? (pageWidth + lastPageWidth) * nextPage
                            : pageWidth * nextPage;

                        // slide the track
                        // eslint-disable-next-line no-param-reassign
                        trackWrapperRef.current.scrollTo({
                            left: trackLeftPosition,
                            behavior: 'smooth',
                        });
                        setTouchedTrack(false);
                    }
                },
                timer,
            ),
        [
            pageWidth,
            lastPageWidth,
            slideTo,
            trackWrapperRef,
            timer,
            touchedTrack,
            setTouchedTrack,
        ],
    );

    return (
        <div
            data-testid="segmented-carousel-track-wrapper"
            ref={trackWrapperRef}
            className={trackWrapperClasses}
            onScroll={handleOnScroll}
            onTouchEnd={() => setTouchedTrack(true)}
            style={{
                paddingRight: !hideOverflow ? rootPaddingRight : undefined,
            }}
        >
            <div
                data-testid="segmented-carousel-track"
                ref={trackRef}
                className={trackClasses}
                style={{
                    padding: `${syncedPadding}px`,
                    gap: `0 ${syncedPadding * 2}px`,
                    gridTemplateColumns: defaults.trackColumns,
                    marginLeft: rootPaddingLeft,
                    marginRight: rootPaddingRight,
                }}
            >
                {pages.map((page, pageIndex) => {
                    const isLastPage = pageIndex === pages.length - 1;
                    const widthPerPage = isLastPage ? lastPageWidth : pageWidth;
                    const columnsPerPage = isLastPage
                        ? lastPageVisibleSegments
                        : visibleSegments || defaults.visibleSegments;
                    return (
                        <div
                            data-testid="segmented-carousel-page"
                            className={`${pageClasses} ${
                                currentPage === pageIndex ? 'currentPage' : ''
                            }`}
                            key={page.key}
                            style={{
                                width: `${widthPerPage - syncedPadding * 2}px`,
                                gap: shouldPaginate
                                    ? `0 ${syncedPadding * 2}px`
                                    : '',
                                gridTemplateColumns: `repeat(${columnsPerPage}, 1fr)`,
                            }}
                        >
                            {Children.map(
                                page.segments,
                                (child, segmentIndex) => {
                                    const visibleSegmentsPerPage = isLastPage
                                        ? lastPageVisibleSegments
                                        : visibleSegments;
                                    const isLastSegment =
                                        segmentIndex ===
                                        visibleSegmentsPerPage - 1;
                                    return (
                                        // an array of React children already has a key index built-in
                                        <SegmentedCarouselSegment
                                            width={
                                                `${segmentWidth}px` ||
                                                `${initDefaults.segmentWidth}px`
                                            }
                                            isFirstSegmentInPage={
                                                pageIndex !== 0 &&
                                                segmentIndex === 0
                                            }
                                            isLastSegmentInPage={isLastSegment}
                                            isLastSegmentInCarousel={
                                                shouldPaginate
                                                    ? isLastPage &&
                                                      isLastSegment
                                                    : isLastPage
                                            }
                                            handleKeyUp={(e) =>
                                                handleKeyUp({ e, pageIndex })
                                            }
                                        >
                                            {child}
                                        </SegmentedCarouselSegment>
                                    );
                                },
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
