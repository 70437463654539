import { gettext } from '@eventbrite/i18n';

import React from 'react';
import { useIsFirstHierarchyLevel } from '../../../experimentation';
import { Highlights } from '../../Highlights';
import { EventDetailsSectionTitle } from '../EventDetails';
import type { LegacyDescription } from './types';

export const OldDescriptionBeforeStructuredContent = ({
    legacyDescription,
    highlights,
}: LegacyDescription) => {
    const hasHighlights =
        highlights && Object.values(highlights).includes(true);
    const isHierarchyExperimentActive = useIsFirstHierarchyLevel();

    return (
        <div className="eds-l-mar-bot-8 eds-l-pad-bot-6 old-description">
            <EventDetailsSectionTitle>
                <h2>{gettext('Description')}</h2>
            </EventDetailsSectionTitle>
            {hasHighlights && !isHierarchyExperimentActive && (
                <Highlights highlights={highlights} />
            )}
            <div className="eds-text-bm">
                {/* Summary comes from an old creation flow */}
                <div
                    className="eds-l-mar-top-3 description-items"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: legacyDescription }}
                ></div>
            </div>
        </div>
    );
};
