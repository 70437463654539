import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Rect from '@reach/rect';
import WindowSize from '@reach/window-size';
import { gettext, translationPropType } from '@eventbrite/i18n';
import { setWindowLocation, openInNewTab } from '@eventbrite/http';

import { Dropdown } from '@eventbrite/eds-containers';
import { IconButton } from '@eventbrite/eds-icon-button';
import { TextList } from '@eventbrite/eds-text-list';

import { ALIGN_PROP_TYPE, ALIGN_RIGHT } from '@eventbrite/eds-containers';
import { TEXT_ITEMS_PROP_TYPE } from '@eventbrite/eds-text-list';
import { STYLE_PROP_TYPE, STYLE_NEUTRAL } from '@eventbrite/eds-icon-button';
import { SVG_PROP_TYPE } from '@eventbrite/eds-vector-image';
import { TYPE_LINK } from '@eventbrite/eds-button';

import { calculateShouldDropUp } from './utils';

import './moreActions.scss';

import { VerticalDotsChunky } from '@eventbrite/eds-iconography';

const Actions = ({ showActions, items, align, onHide, onAction }) => {
    let triggerRef = null;

    if (showActions) {
        const className = classNames(
            'eds-more-actions__dropdown',
            `eds-more-actions--align-${align}`,
        );

        return (
            <WindowSize>
                {(windowSize) => (
                    <Rect observe={true}>
                        {({ rect, ref }) => (
                            <div
                                className={className}
                                ref={(node) => {
                                    triggerRef = node;
                                }}
                                data-spec="actions-dropdown"
                            >
                                <Dropdown
                                    align={align}
                                    onClickOutside={onHide}
                                    dropUp={calculateShouldDropUp({
                                        rect,
                                        windowSize,
                                        triggerRef,
                                    })}
                                >
                                    <TextList
                                        innerRef={ref}
                                        items={items}
                                        onItemSelect={onAction}
                                        shouldCalculateDimensions={true}
                                    />
                                </Dropdown>
                            </div>
                        )}
                    </Rect>
                )}
            </WindowSize>
        );
    }

    return null;
};

export default class MoreActions extends PureComponent {
    static propTypes = {
        /**
         * Array of list item data.
         */
        items: TEXT_ITEMS_PROP_TYPE.isRequired,

        dropdownAlign: ALIGN_PROP_TYPE,
        /**
         * The type of icon that you want to display e.g. alert
         */
        iconType: SVG_PROP_TYPE,
        /**
         * The style of the icon button
         */
        style: STYLE_PROP_TYPE,
        /**
         * Callback function that will be called when a action item is selected
         * (value, index) => {}
         */
        onAction: PropTypes.func,
        /**
         * Configurable title for icon button. Must be the result of gettext() for localization
         */
        title: translationPropType,
        /**
         * Function that will be passed the current visibility state of the action menu
         */
        onToggleVisibility: PropTypes.func,
        /**
         * Disable component
         */
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        iconType: <VerticalDotsChunky />,
        style: STYLE_NEUTRAL,
        dropdownAlign: ALIGN_RIGHT,
        title: gettext('View more actions'),
        isDisabled: false,
    };

    state = {
        showActions: false,
    };

    _toggleDropdown() {
        this.setState(
            ({ showActions: prevShowActions }) => ({
                showActions: !prevShowActions,
            }),
            this._passVisibilityState,
        );
    }

    _hideDropdown() {
        this.setState({ showActions: false }, this._passVisibilityState);
    }

    _handleOnActionItem(value, index, syntheticEvent) {
        const { path, buttonType } = this.props.items[index];

        this._hideDropdown();

        if (this.props.onAction) {
            this.props.onAction(value, index, syntheticEvent, path);
        } else if (buttonType === TYPE_LINK) {
            if (syntheticEvent.shiftKey) {
                window.open(path);
            } else if (syntheticEvent.ctrlKey || syntheticEvent.metaKey) {
                openInNewTab(path);
            } else {
                setWindowLocation(path);
            }
        }
    }

    _passVisibilityState = () => {
        if (this.props.onToggleVisibility) {
            this.props.onToggleVisibility(this.state.showActions);
        }
    };

    render() {
        const { items, dropdownAlign, iconType, style, title, isDisabled } =
            this.props;

        const { showActions } = this.state;
        let ariaExpanded;

        // if the actions are being shown then the aria label should be true
        // otherwise it shouldn't show so it should be `undefined`
        if (showActions) {
            ariaExpanded = true;
        }

        return (
            <div className="eds-more-actions">
                <IconButton
                    iconType={iconType}
                    style={style}
                    onClick={this._toggleDropdown.bind(this)}
                    data-spec="eds-more-actions-toggle"
                    title={title}
                    aria-haspopup={true}
                    aria-expanded={ariaExpanded}
                    isDisabled={isDisabled}
                />
                <Actions
                    showActions={showActions}
                    items={items}
                    align={dropdownAlign}
                    onHide={this._hideDropdown.bind(this)}
                    onAction={this._handleOnActionItem.bind(this)}
                />
            </div>
        );
    }
}
